import React, {Component} from 'react';
import {Paragraph, RowComponent, TextSize} from "medissima-ui-kit/";
import {Weight} from "medissima-ui-kit/lib";

type Data = {
    fields: Fields[]
}

type Fields = {
    name: string,
    value: string
}

class EquipmentSpecifications extends Component<Data> {

    render(){
        return <div className={"equipment-specification"}>
            <Paragraph text={"Характеристики"} color={"#607088"} size={TextSize.P_24}/>
            {this.props.fields.map(i => {
                return <RowComponent>
                    <Paragraph text={i.name ?? "Данные не указаны"} color={"#607088"} size={TextSize.P_18}/>
                    <Paragraph text={i.value ?? "Данные не указаны"} color={"#607088"} size={TextSize.P_18} fontWeight={Weight.BOLD}/>
                </RowComponent>
            })}
        </div>
    }
}

export default EquipmentSpecifications;