import React, {Component} from "react";
import * as dateFns from "date-fns";
import { ru } from 'date-fns/locale'
import "./Service.css"
import {Paragraph, TextSize, Weight} from "medissima-ui-kit";
import {scheduleService} from "./akita/schedule.service";
import {convertDateToFormat} from "../../date";
import {scheduleQuery} from "./akita/schedule.query";
import {Schedule} from "./akita/schedule.model";
import ScaleText from "react-scale-text";
import {ButtonComponent, Span} from "medissima-ui-kit/lib";

type State = {
    currentDate: any,
    selectedDate: Date,
    serviceList: Array<Schedule>
}

class ServiceCalendar extends Component<any, State>{

    constructor(props: Readonly<any>) {
        super(props);
        this.state = {
            currentDate: new Date(),
            selectedDate: new Date(),
            serviceList: []
        }
    }

    componentDidMount(): void {
        let date = new Date()
        scheduleService.loadServiceSchedule(convertDateToFormat(date, "yyyy-MM-dd"))

        scheduleQuery.selectAll()
            .subscribe(items =>{
                this.setState({
                    ...this.state,
                   serviceList: items
                })
            })
    }

    renderCalendarHeader = () => {
        return <div className="service-calendar--header row">
                <h2>{dateFns.format(this.state.currentDate, "MMMM yyyy", { locale: ru })}</h2>
            </div>

    };

    daysOfWeek = () => {
        const dateFormat = "E";
        const days = Array();
        let startDate = dateFns.startOfISOWeek(this.state.currentDate);
        for (let i = 0; i < 7; i++) {
            days.push(
                <div className="service-calendar--column" key={i}>
                    <span>{dateFns.format(dateFns.addDays(startDate, i), dateFormat, {locale: ru})}</span>
                </div>
            );
        }
        return <div className="days row">{days}</div>;
    };

    renderCalendarCells = () => {
        const {currentDate} = this.state;
        const monthStart = dateFns.startOfMonth(currentDate);
        const monthEnd = dateFns.endOfMonth(monthStart);
        const startDate = dateFns.startOfISOWeek(monthStart);
        const endDate = dateFns.endOfISOWeek(monthEnd);
        const dateFormat = "d";
        const rows = Array();
        let days = Array();
        let day: any = startDate;
        let formattedDate = "";
        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = dateFns.format(day, dateFormat);
                days.push(
                    <div
                        className={`column cell ${!dateFns.isSameMonth(day, monthStart)
                            ? "disabled" : dateFns.isSameDay(day, this.state.selectedDate)
                                ? "selected" : ""} ${this.determineWeekend(day)}`}
                        key={day}
                    >
                        <span className="number">{formattedDate}</span>
                        {this.state.serviceList.map(i => {
                            if(convertDateToFormat(new Date(i.date), "dd.MM.yyyy") == convertDateToFormat(day,"dd.MM.yyyy")){
                                return this.addDescription(i.name, i.job_type)
                            }
                        })}
                    </div>
                );
                day = dateFns.addDays(day, 1);
            }
            rows.push(
                <div className="row" key={day}> {days} </div>
            );
            days = [];
        }
        return <div className="service-calendar--body">{rows}</div>;
    };

    determineWeekend(day){
        let checkedDay = dateFns.getDay(day);
        if (checkedDay === 0 || checkedDay === 6) {
            return "weekend"
        }
    };

    addDescription(name, job){
        return <div className={"description-service"}>
            <ScaleText widthOnly={true} maxFontSize={18} >
                <p style={{fontWeight: "bold"}}>{name}</p>

            </ScaleText>
            <ScaleText widthOnly={false} minFontSize={8} maxFontSize={18} >
                <p>{job}</p>
            </ScaleText>
        </div>
    };

    render(){
        return <div className="service-calendar--container">
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
            }}>
                <h1>График обслуживания</h1>
                <ButtonComponent text={"Закрыть"} onClick={() => {this.props.history.goBack()}} background={"#40D094"}/>
            </div>
            {this.renderCalendarHeader()}
            {this.daysOfWeek()}
            {this.renderCalendarCells()}
        </ div>
    }
}

export default ServiceCalendar;