import React, {Component} from "react";
import "../css/Modals.css"
import {FileIcon, H2, Paragraph, RowComponent, SettingIcon, TextSize, Weight} from "medissima-ui-kit";
import {ID} from "@datorama/akita";
import {journalService} from "../../journal/journal.service";
import {
    createInitialState,
    JournalState,
    journalStore,
    Periodicity,
    Status,
    TypeJob
} from "../../journal/journal.store";
import {journalQuery} from "../../journal/journal.query";
import {convertDateToFormat} from "../../../date";

type Data = {
    id: ID
}

class ServiceModal extends Component<Data, JournalState> {

    constructor(props: Readonly<Data>) {
        super(props);
        this.state = createInitialState()
    }

    componentDidMount(): void {
        journalStore.reset()
        journalService.loadingJournal(this.props.id)

        journalQuery.select()
            .subscribe( object => {
                this.setState(object)
            })
    }

    render() {
        return <div className={"modal--service"}>
            <div className={"modal--service-title"}>
                <SettingIcon fill={"#72AAFC"} height={"32px"} width={"32px"}/>
                <div>
                    <H2 text={this.state.review.name} color={"#303030"}/>
                    <Paragraph text={convertDateToFormat(new Date(this.state.date))} color={"#808080"} size={TextSize.P_18}/>
                </div>
            </div>
            <div className={"modal--service-journal"}>
                <RowComponent>
                    <Paragraph text={"Вид работ"} color={"#607088"} size={TextSize.P_18}/>
                    <Paragraph text={this.mapTypeJob(this.state.review.type)} color={"#607088"} size={TextSize.P_18} fontWeight={Weight.BOLD}/>
                    <Paragraph text={this.mapPeriodicity(this.state.review.periodicity)} color={"#607088"} size={TextSize.P_18} fontWeight={Weight.BOLD}/>
                </RowComponent>
                <RowComponent>
                    <Paragraph text={"Статус"} color={"#607088"} size={TextSize.P_18}/>
                    <Paragraph text={this.mapStatus(this.state.status)} color={"#607088"} size={TextSize.P_18} fontWeight={Weight.BOLD}/>
                </RowComponent>
                <RowComponent>
                    <Paragraph text={"Что сделано"} color={"#607088"} size={TextSize.P_18}/>
                    <Paragraph text={this.state.what_was_done ?? "Данные отсутствуют"} color={"#607088"} size={TextSize.P_18} fontWeight={Weight.BOLD}/>
                </RowComponent>
                <RowComponent>
                    <Paragraph text={"Запасные части"} color={"#607088"} size={TextSize.P_18}/>
                    <Paragraph text={this.state.repair_parts ?? "Данные отсутствуют"} color={"#607088"} size={TextSize.P_18} fontWeight={Weight.BOLD}/>
                </RowComponent>
                <RowComponent>
                    <Paragraph text={"Принял (ФИО)"} color={"#607088"} size={TextSize.P_18}/>
                    <Paragraph text={this.state.acceptor ?? "Данные отсутствуют"} color={"#607088"} size={TextSize.P_18} fontWeight={Weight.BOLD}/>
                </RowComponent>
                <RowComponent>
                    <Paragraph text={"Выполнил (Организация)"} color={"#607088"} size={TextSize.P_18}/>
                    <Paragraph text={this.state.executor ?? "Данные отсутствуют"} color={"#607088"} size={TextSize.P_18} fontWeight={Weight.BOLD}/>
                </RowComponent>
            </div>
            <div className={"modal--service-download-report"}>
                <FileIcon/>
                <a download href={`${this.state.verification_certificate}`} style={{color: "#72AAFC", marginLeft:"10px"}}>Скачать отчет</a>
            </div>
        </div>
    }

    private mapPeriodicity(period?: Periodicity): string {
        let result
        switch (period) {
            case Periodicity.EVERY_MONTH:
                result = "Каждый месяц";
                break;
            case Periodicity.EVERY_QUARTER:
                result = "Каждый квартал";
                break;
            case Periodicity.EVERY_HALF_YEAR:
                result = "Каждые полгода";
                break;
            case Periodicity.EVERY_YEAR:
                result = "Каждый год"
                break;
            default:
                result = "Неизвестно"
        }
        return result
    }

    private mapTypeJob(type?: TypeJob): string {
        let result
        switch (type) {
            case TypeJob.MAINTENANCE:
                result = "Техническое обслуживание";
                break;
            case TypeJob.VERIFICATION:
                result = "Поверка";
                break;
            default:
                result = "Неизвестно"
        }
        return result
    }

    private mapStatus(status?: Status): string {
        let result
        switch (status) {
            case Status.EXPIRED:
                result = "Просрочено";
                break;
            case Status.ASSIGNED:
                result = "Назначено";
                break;
            case Status.PLANNED:
                result = "Запланировано";
                break;
            case Status.DONE:
                result = "Выполнено";
                break;

            default:
                result = "Неизвестно"
        }
        return result

    }



}

export default ServiceModal;