import { QueryEntity } from '@datorama/akita';
import { RegistrationStore, RegistrationState, registrationStore } from './registration.store';

export class RegistrationQuery extends QueryEntity<RegistrationState> {

  constructor(protected store: RegistrationStore) {
    super(store);
  }

}

export const registrationQuery = new RegistrationQuery(registrationStore);
