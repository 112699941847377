import React, {Component} from "react";
import {Align, TextSize, H2, Paragraph, PasswordInput, Span, SubmitButton} from "medissima-ui-kit";
import {registrationService} from "./registration.service";
import {registrationQuery} from "./registration.query";


type Data = {
  errors?: {
    new_password?: string,
    repeat_password?: string
  },
  requestId?: string,
  onFormSubmit: (requestId?: string) => void
}

class RegistrationPassword extends Component<Data> {

  state = {
    error: undefined,
    password: "",
    passwordConfirmation: "",
    isDisabled: false
  };

  componentDidMount(): void {
    registrationQuery.selectError()
      .subscribe(error => {
        this.setState({
          ...this.state,
          error: error
        })
      })
  }

  render() {
    return <div className="auth-form--container">
      <H2 className={"auth-form--title"} text={"Регистрация"} color={"#FFFFFF"}/>
      <form onSubmit={(event) => {
        this.handleOnFormSubmit(event)
      }}>
        <PasswordInput
            onInputChange={(value: string) => {
              this.onChangeInputPassword(value)
            }}
            id={"new_password"}
            placeholder={"Придумайте пароль"}/>
        <PasswordInput
            onInputChange={(value: string) => {
              this.onChangeInputPasswordConfirmation(value)
            }}
            id={"repeat_password"}
            placeholder={"Повторите пароль"}
            error={this.state.error}
        />

        <Paragraph
            className={this.state.error ? 'hidden' : ''}
            text={this.state.error}
            size={TextSize.P_18}
            color={"#FFFFFF"}
            align={Align.CENTER}/>
        <SubmitButton className={"registration-password--button"} isDisabled={this.state.isDisabled} text={"Далее"}/>
      </form>
      <Span text={'3/4'} color={"#FFFFFF"}/>
    </div>
  }


  private handleOnFormSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    this.formValidation();
  }

  private formValidation() {
    console.log(this.props);
    registrationService.sendPassword(
        {
            request_id: this.props.requestId,
            password: this.state.password,
            password_confirmation: this.state.passwordConfirmation
        },
        requestId => {
          this.props.onFormSubmit(requestId);
        });
  }

  private onChangeInputPassword(value: string) {
    this.setState({
      ...this.state,
      password: value,
      isDisabled: false,
      error: undefined,
    });
  }

  private onChangeInputPasswordConfirmation(value: string) {
    this.setState({
      ...this.state,
      passwordConfirmation: value,
      isDisabled: false,
      error: undefined,
    });
  }

  private errorMethod() {
    let error = 'Пароль слишком короткий';
    let isDisabled = true;
    this.setState({error: error, isDisabled: isDisabled})
  }
}

export default RegistrationPassword;