import React, {Component} from "react";
import UserProfile from "./UserProfile";
import SidebarMenu from "./menu/SidebarMenu";
import {profileService} from "../user_settings/profile/profile.service";
import {AuthLogo, H3} from "medissima-ui-kit";

type Data = {
    className?: string
}

class Sidebar extends Component<Data> {

    componentDidMount(): void {
        profileService.loadProfile();
    }

    render() {
        return <aside className={`${this.props.className ?? ""} sidebar--container col col-sm-3`} >
            <div className={"sidebar-logo"}>
                <a href={"/#/dashboard"}>
                    <AuthLogo width={"64px"} height={"64px"}/>
                    <H3 text={"medissima"} color={"#FFF"}/>
                </a>
            </div>
            <SidebarMenu/>
            <UserProfile className={"sidebar--user-profile"}/>
        </aside>
    }
}

export default Sidebar;