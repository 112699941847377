import { QueryEntity } from '@datorama/akita';
import { PermissionsStore, PermissionsState, permissionsStore } from './permissions.store';

export class PermissionsQuery extends QueryEntity<PermissionsState> {

  constructor(protected store: PermissionsStore) {
    super(store);
  }

}

export const permissionsQuery = new PermissionsQuery(permissionsStore);
