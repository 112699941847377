import { Store, StoreConfig } from '@datorama/akita';

export type FilterItem = {

}

export interface FilterState {
  date: string,
  job_type: string,
  equipment: string
}

export function createInitialState(): FilterState {
  return {
    date: "",
    job_type: "",
    equipment: ""
  };
}

@StoreConfig({ name: 'filter' })
export class FilterStore extends Store<FilterState> {

  constructor() {
    super(createInitialState());
  }

}

export const filterStore = new FilterStore();

