import React, {Component} from 'react';
import {EquipmentListItem, H2} from "medissima-ui-kit";
import {ID} from "@datorama/akita";
import {EquipmentList} from "../equipment_state/user-equipment.model";


type Data = {
  title?: string,
  onClick: (id: ID) => void,
  equipmentList: EquipmentList[]
}

class EquipmentsList extends Component<Data> {

  render() {
    return <div className={"equipment-by-category"}>
      <H2 text={this.props.title}/>
      <div
        className={"equipment-by-category--list"}
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between"
        }}
      >
        {
          this.props.equipmentList.map(i => {
              return <EquipmentListItem id={i.id}
                                        address={i.address}
                                        category={i.category}
                                        name={i.name}
                                        onClick={() => this.props.onClick(i.id)}
              />
            }
          )
        }
      </div>
    </div>
  }

}

export default EquipmentsList;