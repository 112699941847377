import React, {Component} from "react";
import "./Filter.css"
import {
    ButtonComponent,
    CalendarIcon,
    DropDownComponent,
    FilterListItem,
    Paragraph,
    TextSize,
    Weight
} from "medissima-ui-kit/lib";
import Calendar from 'react-calendar';
import {convertDateToFormat} from "../../date";
import {Schedule} from "../service_shedule/akita/schedule.model";
import {scheduleQuery} from "../service_shedule/akita/schedule.query";
import FilterParams from "./FilterParams";
import {scheduleService} from "../service_shedule/akita/schedule.service";
import * as dateFns from "date-fns";
import { ru } from 'date-fns/locale'
import ScaleText from "react-scale-text";

type Data = {
    list: Array<any>,
    onFilter: (tuples: [string, string?][]) => void
}

type State = {
    isPickerActive?: boolean,
    date?: Date,
    selectedName?: string,
    selectedJob?: string,
    filterItems: Schedule[]
    filterSelected?: [string, string?][],
    resetJob?: boolean,
    resetEquipment?: boolean
}

class ServiceFilter extends Component<Data, State> {
    constructor(props: Readonly<any>) {
        super(props);
        this.state = {
           isPickerActive:false,
           filterItems: [],
            date: new Date
        }
    }

    componentDidMount(): void {
        scheduleQuery.selectAll()
            .subscribe(items =>{
                this.setState({
                    ...this.state,
                    filterItems: items
                })
            })
    }

    render() {
        return <div className={"filter-area"}>
            <div className={"filter-area--selection"}>
                <div className={"date-picker"}>
                    <CalendarIcon
                        fill={"#40D094"}
                        onClick={() => this.setState({
                            ...this.state,
                            isPickerActive: !this.state.isPickerActive
                        })}
                    />
                    <Calendar
                        className={this.state.isPickerActive ? "": "hidden"}
                        onClickMonth={date => this.onDatePicked(date)}
                        value={this.state.date ?? new Date()}
                        view='year'
                    />
                    <Paragraph text={`Мероприятия ${dateFns.format(this.state.date ?? new Date(), "MMMM yyyy", { locale: ru })}`} color={"#607088"} size={TextSize.P_18}/>
                </div>
                <div className={"filter-area--selection--other"}>
                    <DropDownComponent
                        id={"service--filter-area--type-work"}
                        placeholder={"Вид работ"}
                        items={Array.from(new Set(this.state.filterItems.map(i => i.job_type)))}
                        onItemSelected={(_: number, value: string) => this.onJobSelected(value)}
                        reset={this.state.resetJob}
                    />
                    <DropDownComponent
                        id={"service--filter-equipment"}
                        placeholder={"Оборудование"}
                        onItemSelected={(_: number, value: string) => this.onEquipmentSelected(value)}
                        items={Array.from(new Set(this.state.filterItems.map(i => i.name)))}
                        reset={this.state.resetEquipment}
                    />
                    <div className={"company--filter-area--action-buttons"}>
                        <ButtonComponent text={"Применить"} background={"#40D094"} onClick={() => this.handleOnSubmit()} fontWeight={Weight.BOLD} />
                        <ButtonComponent text={"Сбросить"} background={"#FC5858"} onClick={() => this.handleOnReset()} fontWeight={Weight.BOLD} />
                    </div>
                </div>
            </div>
            <div className={"filter-area--bottom"}>
                <div className={"filter-area--selected-items"}>
                    {
                        this.state.filterSelected?.map(i => {
                            if(!i[1]) return;

                            return <FilterListItem text={`${i[0]}: ${i[1]}`} id={i[0]} onRemove={(id) => this.handleOnRemove(id)} />
                        })
                    }
                </div>
            </div>
        </div>
    }

    private onEquipmentSelected(value: string) {
        this.setState({
            ...this.state,
            selectedName: value,
            resetEquipment: false
        })
    }

    private onJobSelected(value: string) {
        this.setState({
          ...this.state,
          selectedJob: value,
            resetJob: false
        })
    }

    private onDatePicked(date: Date) {
        this.setState({
            ...this.state,
            date: date,
            isPickerActive: false
        })
    }

    private handleOnSubmit() {
        let date = convertDateToFormat(this.state.date, "yyyy-MM-dd")
        scheduleService.filterByMonth(date)

        const tuples: [string, string?][] = [
            [FilterParams.DATE, convertDateToFormat(this.state.date, "yyyy-MM-dd")],
            [FilterParams.EQUIPMENT, this.state.selectedName],
            [FilterParams.JOB, this.state.selectedJob]
        ]
        this.setState({
            ...this.state,
            filterSelected: tuples
          })
        this.props.onFilter(tuples)
    }

    private handleOnRemove(id: string|number) {
        this.setState({
          ...this.state,
          filterSelected: this.state.filterSelected?.filter(i => i[0] != id)
        })
    }

    private handleOnReset() {
        let date = convertDateToFormat(new Date, "yyyy-MM-dd");
        scheduleService.filterByMonth(date);

        this.props.onFilter([])
        this.setState({
            filterSelected: [],
            date: new Date,
            resetEquipment: true,
            resetJob: true,
            selectedJob: undefined,
            selectedName: undefined
        })
    }
}

export default ServiceFilter;