import { Store, StoreConfig } from '@datorama/akita';

export interface DashboardState {
  equipment_reviews_state: EquipmentReviewsState;
  expired: Expired[];
  nearest_maintainable: NearestMaintainable[];
  nearest_reviews: NearestReview[]
}

export interface EquipmentReviewsState  {
  expired: number;
  processed: number;
  total: number;
}

export interface Expired {
  date: string;
  name: string;
  status: string;
}

export interface NearestMaintainable {
  date: string;
  name: string;
  status: string;
}

export interface NearestReview  {
  date: string;
  name: string;
  status: string;
}


export function createInitialState(): DashboardState {
  return {
    equipment_reviews_state: {
      expired: 0,
      processed: 0,
      total: 0
    },
    expired: [
      {
        date: "",
        name: "",
        status: ""
      }
    ],
    nearest_maintainable: [
      {
        date: "",
        name: "",
        status: ""
      }
    ],
    nearest_reviews: [
      {
        date: "",
        name: "",
        status: ""
      }
    ]

  };
}

@StoreConfig({ name: 'dashboard', resettable: true })
export class DashboardStore extends Store<DashboardState> {

  constructor() {
    super(createInitialState());
  }

}

export const dashboardStore = new DashboardStore();

