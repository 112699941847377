import React, {Component} from 'react';
import {
    Align,
    Center,
    EquipmentListItem,
    Loader,
    PageHeading,
    Paragraph
} from "medissima-ui-kit";
import "../../css/Equipment.css"
import {ID} from "@datorama/akita";
import {paginationService} from "../pagination/pagination.service";
import {equipmentQuery} from "./equipment_state/equipment.query";
import {categoryQuery} from "./category_state/category.query";
import {equipmentService} from "./equipment_state/equipment.service";
import {Equipment} from "./equipment_state/equipment.model";
import {Category} from "./category_state/category.model";

type State = {
    isLoading: boolean,
    isEmpty: boolean,
    equipment: Equipment[],
    category?: Category
}

class EquipmentsSection extends Component<any, State> {

    constructor(props: Readonly<any>) {
        super(props);

        this.state = {
            isLoading: true,
            isEmpty: true,
            equipment: []
        }
    }

    componentWillUnmount(): void {
        paginationService.reset()
    }

    componentDidMount(): void {
        const categoryID = this.props.match.params.id;
        equipmentService.loadEquipmentByCategory(categoryID)

        equipmentQuery.selectLoading()
            .subscribe(loading => {
                this.setState({
                    ...this.state,
                    isLoading: loading
                })
            });

        categoryQuery.selectEntity(categoryID)
            .subscribe(i => {
                this.setState({
                    ...this.state,
                    category: i
                })
            });

        equipmentQuery.selectAll()
            .subscribe(items =>{
                this.setState({
                    ...this.state,
                    equipment: items,
                    isEmpty: items.length == 0
                })
            });
    }

    render() {
        return <div className={"equipment-catalog--page"}>
            <nav aria-label="breadcrumb" className="bread-crumb--component">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">Личный кабинет</a></li>
                    <li className="breadcrumb-item"><a href="/#/catalog">Каталог оборудования</a></li>
                    <li className="breadcrumb-item active" aria-current="page">{this.state?.category?.name}</li>
                </ol>
            </nav>
            <PageHeading title={this.state?.category?.name} onBack={() => {this.props.history.goBack()}}/>
            <div>
                <Center>
                    <Loader visible={this.state.isLoading}/>
                </Center>
                {
                    this.state.isEmpty ?
                        <Paragraph className={"empty-text"} text={"Список оборудования пуст"} align={Align.CENTER}/> : null
                }
            </div>
            <div className={"equipment-by-category--list"}
                 style={{
                     display: "flex",
                     flexWrap: "wrap",
                     justifyContent:"space-between"
                 }}
            >
                {
                    this.state.equipment.map(i => {
                        return <EquipmentListItem
                            id={i.id}
                            name={i.name}
                            category={i.category}
                            onClick={(id:ID)=> this.handleEquipmentView(id) }
                            onEdit={() =>{}}
                        />
                    })
                }
            </div>
        </div>
    }

    handleEquipmentView(id:ID){
        this.props.history.push(`/catalog/${id}`)

    }

}

export default EquipmentsSection;



