import { ID } from '@datorama/akita';

export interface Permission {
  id: ID;
  granted: boolean
}

export interface PermissionMapping {
  rule: string,
  granted: boolean
}

export enum Rules {
  EDIT = "edit",
  DELETE = "delete",
  OWNER = "owner"
}

export function createPermission(params: Partial<Permission>) {
  return {
    ...params
  } as Permission;
}
