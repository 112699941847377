import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css'
import {akitaConfig, akitaDevtools, persistState} from "@datorama/akita";
import * as localForage from 'localforage';
import 'react-toastify/dist/ReactToastify.css';
import 'medissima-ui-kit/lib/index.css'
import 'react-calendar/dist/Calendar.css';
import {HttpClient} from "medissima-http-client/lib";
import {authQuery} from "./app/auth/auth.query";
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';


akitaDevtools();
akitaConfig({
    resettable: true
})

localForage.config({
  driver: localForage.INDEXEDDB,
  name: 'medissima',
  version: 1.0,
  storeName: 'akita-storage'
});

persistState({
  include: ["authorization_store", "permissions",
  "category"],
  storage: localStorage
});

export let http: HttpClient = new HttpClient(
  {
    token: authQuery.findAuthSession()?.token
  },
  {
    backendUrl: process.env.REACT_APP_BACKEND_URL,
    httpTimeOut: Number.parseInt(process.env.REACT_APP_HTTP_TIMEOUT ?? "20000")
  }
)

authQuery.first()
  .subscribe(user => {
    http = new HttpClient(
      {
        token: authQuery.findAuthSession()?.token
      },
      {
        backendUrl: process.env.REACT_APP_BACKEND_URL,
        httpTimeOut: Number.parseInt(process.env.REACT_APP_HTTP_TIMEOUT ?? "20000")
      }
    )
  })

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
