import { QueryEntity } from '@datorama/akita';
import { EquipmentStore, EquipmentState, equipmentStore } from './equipment.store';

export class EquipmentQuery extends QueryEntity<EquipmentState> {

  constructor(protected store: EquipmentStore) {
    super(store);
  }

}

export const equipmentQuery = new EquipmentQuery(equipmentStore);
