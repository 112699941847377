import React, {Component} from "react";
import {Nothing} from "../../models/Nothing";
import RestorePasswordForm from "./RestorePasswordForm";
import {AuthLogo} from "medissima-ui-kit";

class PasswordRestore extends Component<Nothing, Nothing> {

  render() {
    return <div className={'authorization-form--container'}
      style={{
      backgroundImage: `url(${process.env.PUBLIC_URL + "auth_back.jpg"})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%"
    }}>
      <AuthLogo className={"auth--logo"} />
      <RestorePasswordForm onFormSubmit={()=>{}} />
    </div>;
  }

}

export default PasswordRestore;