import React, {Component} from "react";
import "../css/Modals.css"
import {H1, Paragraph, SuccessIcon, TextSize} from "medissima-ui-kit";

type Data = {
  title: string,
  text?: string
}

class SuccessModal extends Component<Data, any> {

  render() {
    return <div className={"modal--success"}>
      <SuccessIcon className={"modal--success-icon"}/>
      <H1 text={this.props.title} color={"#FFFFFF"}/>
      <Paragraph size={TextSize.P_24} text={this.props.text}/>
    </div>
  }

}

export default SuccessModal;