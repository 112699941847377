import { ID } from '@datorama/akita';
import { PermissionsStore, permissionsStore } from './permissions.store';
import {createPermission, Permission, PermissionMapping} from "./permission.model";
import {http} from "../../index";

export class PermissionsService {

  constructor(private permissionsStore: PermissionsStore) {
  }

  updatePermissions() {
    http.get<PermissionMapping[]>("/api/access/employers")
        .then(response => {
          response.data.map(i => {
            permissionsStore.upsert(i.rule, createPermission({
              id: i.rule,
              granted: i.granted
            }))
          })
        })
  }

}

export const permissionsService = new PermissionsService(permissionsStore);