import { ID } from '@datorama/akita';
import { DashboardStore, dashboardStore } from './dashboard.store';
import {http} from "../../../../index";
import {ModalsStateType, modalsStore} from "../../../modals/state/modals.store";
import {Nothing} from "../../../../models/Nothing";
import {ResponseWrapper} from "medissima-http-client/lib";

export class DashboardService {

  constructor(private dashboardStore: DashboardStore) {
  }

  getDashboardState():Promise<void>{
    return http.get("/api/dashboard")
        .then(response =>{
         dashboardStore.update(response.data)
        })
        .catch(reason =>{
            modalsStore.update({
                key: ModalsStateType.ERROR,
                payload: (reason as ResponseWrapper<Nothing>).message
            })
        })
  }

}

export const dashboardService = new DashboardService(dashboardStore);