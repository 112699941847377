import { ID } from '@datorama/akita';
import {
    EquipmentProfileState,
    EquipmentProfileStore,
    equipmentProfileStore,
    UpdateEquipment
} from './equipment-profile.store';
import {http} from "../../../index";
import {equipmentItemStore} from "../../catalog/equipment-item/equipment-item.store";
import {ModalsStateType, modalsStore} from "../../modals/state/modals.store";
import {Nothing} from "../../../models/Nothing";
import {ResponseWrapper} from "medissima-http-client/lib";
import {userEquipmentStore} from "../equipment_state/user-equipment.store";

export class EquipmentProfileService {

  constructor(private equipmentProfileStore: EquipmentProfileStore) {
  }

  getEquipmentItem(id: ID){
      equipmentItemStore.setLoading(true)

      return http.get(`/api/registry/view/${id}`)
          .then(response => {
              equipmentProfileStore.update(response.data)
          })
          .catch(reason =>{
              modalsStore.update({
                  key: ModalsStateType.ERROR,
                  payload: (reason as ResponseWrapper<Nothing>).message
              })
          })
          .finally(() => {
              equipmentItemStore.setLoading(false)
          })
  }

    updateEquipment(data: UpdateEquipment){
        http.put<EquipmentProfileState>(`api/registry/update`, data)
            .then(response => {
                userEquipmentStore.update(response.data)
            })
            .then(response => {
                modalsStore.update({
                    key: ModalsStateType.SUCCESS,
                    payload: {
                        text: "Настройки оборудования успешно сохранены"
                    }
                })
            })
            .catch(reason => {
                modalsStore.update({
                    key: ModalsStateType.ERROR,
                    payload: {
                        error: (reason as ResponseWrapper<Nothing>).message
                    }
                })
            })
    }

}

export const equipmentProfileService = new EquipmentProfileService(equipmentProfileStore);