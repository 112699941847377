import { ID } from '@datorama/akita';

export interface Employee {
  id: ID;
  name: string;
  email: string;
  phone: string;
  is_admin: boolean;
  is_super_admin: boolean;
}

export function createEmployee(params: Partial<Employee>) {
  return {
      ...params
  } as Employee;
}
