import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { UserEquipment } from './user-equipment.model';

export interface UserEquipmentState extends EntityState<UserEquipment> {}

@StoreConfig({
  name: 'user_equipment',
  idKey: '_id',
  resettable: true
})
export class UserEquipmentStore extends EntityStore<UserEquipmentState> {

  constructor() {
    super();
  }

}

export const userEquipmentStore = new UserEquipmentStore();

