import React, {Component} from "react";
import {H2, SubmitButton, TextInput} from "medissima-ui-kit";

type Data = {
    error?: string,
    onFormSubmit: () => void
}

class RestorePasswordForm extends Component<Data> {

  render() {
      return <div className="auth-form--container">
          <H2 className={"auth-form--title"} text={"Восстановление пароля"} color={"#FFFFFF"}/>
          <form onSubmit={event => this.handleOnFormSubmit(event)}>
              <TextInput id={'email'} placeholder={'Ваш Email'} error={this.props.error}/>
              <SubmitButton className={"registration--button"} text={"Восстановить"} isDisabled={this.props.error !== undefined}/>
          </form>
      </div>

  }
  handleOnFormSubmit(event: React.FormEvent<HTMLFormElement>){
      event.preventDefault();
      this.props.onFormSubmit();
  }

}

export default RestorePasswordForm;
