import { QueryEntity } from '@datorama/akita';
import { ScheduleStore, ScheduleState, scheduleStore } from './schedule.store';

export class ScheduleQuery extends QueryEntity<ScheduleState> {

  constructor(protected store: ScheduleStore) {
    super(store);
  }

}

export const scheduleQuery = new ScheduleQuery(scheduleStore);
