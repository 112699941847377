import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { NotificationItem } from './notification.model';

export interface NotificationsState extends EntityState<NotificationItem> {}

@StoreConfig({
  name: 'notifications',
  idKey: '_id',
  resettable: true
})
export class NotificationsStore extends EntityStore<NotificationsState> {

  constructor() {
    super();
  }

}

export const notificationsStore = new NotificationsStore();

