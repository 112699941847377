import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Permission } from './permission.model';

export interface PermissionsState extends EntityState<Permission> {}

@StoreConfig({
  name: 'permissions',
  idKey: '_id',
  resettable: true
})
export class PermissionsStore extends EntityStore<PermissionsState> {

  constructor() {
    super();
  }

}

export const permissionsStore = new PermissionsStore();

