import React, {Component} from 'react';
import './Settings.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import EmployeesList from "./employees/EmployeesList";
import {PageHeading} from "medissima-ui-kit";

class EmployeesPage extends Component<any> {

    render(){
        return <div className={"user-setting--page"}>
            <Breadcrumb className={"bread-crumb--component"}>
                <Breadcrumb.Item href={"/#/dashboard"}>Личный кабинет</Breadcrumb.Item>
                <Breadcrumb.Item href={"/#/settings"}>Мои настройки</Breadcrumb.Item>
                <Breadcrumb.Item active>Сотрудники</Breadcrumb.Item>
            </Breadcrumb>
            <PageHeading title={"Сотрудники"} onBack={() => {this.props.history.goBack()}}/>
            <EmployeesList/>
        </div>
    }
}

export default EmployeesPage;