import { Query } from '@datorama/akita';
import { ModalsStore, ModalsState, modalsStore } from './modals.store';

export class ModalsQuery extends Query<ModalsState> {

  constructor(protected store: ModalsStore) {
    super(store);
  }

}

export const modalsQuery = new ModalsQuery(modalsStore);