import React, {Component} from "react";
import DashboardMain from "./dashboard_main/DashboardMain";

type Data = {
    className?: string
}

class DashboardView extends Component<Data> {

    render() {
        return <DashboardMain />
    }
}

export default DashboardView;