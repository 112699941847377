import React, {Component} from "react";
import DropMenu from "./DropMenu";
import {Accordion} from "react-bootstrap";
import {Button, Card, CardBody} from "reactstrap";
import {Link} from "react-router-dom";
import {navigationStore} from "../../user_equipment/nav_store/navigation.store";
import {categoryService} from "../../catalog/category_state/category.service";
import {Category} from "../../catalog/category_state/category.model";

type Data = {
    eventKey: number,
    onClick: (key: number) => void,
    className?: string
}

type State = {
    categoryList: Category[],
    total: number
}

class UserEquipment extends Component<Data, State> {
    constructor(props: Readonly<Data>) {
        super(props);
        this.state = {
            categoryList: [],
            total: 0
        }
    }

    componentDidMount(): void {
        categoryService.loadCategoryForMenu()
            .then(items => {
                this.setState({
                    ...this.state,
                    categoryList: items,
                    total: items.map(i => i.total ?? 0)
                        .reduce((a, i) => Math.floor(a) + Math.floor(i))
                })
            })
    }

    render() {
        return <Card>
            <Accordion.Toggle className={"menu-link-button"} as={Button} variant="link"  eventKey={`${this.props.eventKey}`}>
                <Link to={"/equipment"} onClick={event => this.handleOnClick(event)} >
                    <div className={`menu-item ${this.props.className}`} >
                        <svg width="48" height="46" viewBox="0 0 48 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0)">
                                <path d="M39.4738 0.875H8.52647C3.84594 0.875 0.0377197 4.23209 0 8.53683V26.5586C0.0377197 30.8836 3.84594 34.394 8.52647 34.394H18.6822V37.4195H10.7988C8.70738 37.4195 7.00597 39.2405 7.00597 41.4788V43.7732C7.00597 44.4889 7.63585 45.0695 8.41221 45.1249H39.5877C40.3641 45.0695 40.9939 44.4892 40.9939 43.7732V41.4785C40.9939 39.2405 39.2925 37.4195 37.2011 37.4195H29.3177V34.394H39.4734C44.154 34.394 47.9622 30.8836 47.9999 26.5687V8.54696C47.9622 4.23209 44.1543 0.875 39.4738 0.875V0.875ZM37.2011 40.0123C37.664 40.0123 38.1814 40.6396 38.1814 41.4788V42.4768H9.81846V41.4785C9.81846 40.6392 10.3363 40.0123 10.7988 40.0123H37.2011ZM21.4947 37.4195V34.394H26.5052V37.4195H21.4947ZM45.1497 26.5687C45.1497 29.4539 42.6034 31.8012 39.4738 31.8012H8.52647C5.39647 31.8012 2.85021 29.4539 2.85021 26.5687V23.48H45.1497V26.5687ZM45.1497 20.8873H2.85021V14.3864H12.5233C12.8851 14.3864 13.233 14.2578 13.4948 14.0276L16.2879 11.5668L21.0362 16.4184C21.2981 16.6862 21.6694 16.8415 22.0605 16.8465C22.4498 16.8536 22.8273 16.7061 23.0976 16.4455L27.958 11.7521L31.3714 16.2888C31.634 16.6382 32.0657 16.8465 32.5268 16.8465H45.1497V20.8873ZM45.1497 14.2538H33.2625L29.2866 8.96896C29.046 8.64892 28.6618 8.44568 28.2407 8.4153C27.8192 8.38356 27.4057 8.53076 27.1138 8.81265L22.1092 13.6447L17.4067 8.83932C17.1522 8.57937 16.794 8.42509 16.4146 8.41226C16.0349 8.39808 15.6657 8.52839 15.3907 8.77045L11.9593 11.794H2.85021V8.54696C2.85021 5.66183 5.39647 3.3145 8.52647 3.3145H39.4738C42.6034 3.3145 45.1497 5.66183 45.1497 8.54696V14.2538Z" fill="white"/>
                                <path d="M22.4117 28.937H25.5883C26.365 28.937 26.9945 28.3563 26.9945 27.6406C26.9945 26.9246 26.365 26.3442 25.5883 26.3442H22.4117C21.6354 26.3442 21.0055 26.9246 21.0055 27.6406C21.0055 28.3563 21.6354 28.937 22.4117 28.937Z" fill="white"/>
                            </g>
                            <defs>
                                <clipPath id="clip0">
                                    <rect y="0.875" width="48" height="44.25" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <div className={"menu-item-title"}>
                            <p>Мое оборудование <span>({this.state.total})</span></p>
                        </div>
                    </div>
                </Link>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={`${this.props.eventKey}`}>
                <CardBody className={"drop-menu--list"}>
                    {this.state.categoryList.map(i => {
                        return <DropMenu
                            textDropMenu={i.name}
                            onClick={() => this.hanleOnEquipmentByCategory(i.name) }
                            hrefItem={"/#/equipment"}
                        />
                    })}
                </CardBody>
            </Accordion.Collapse>
        </Card>
    }

    private hanleOnEquipmentByCategory(name: string){
        navigationStore.update({
            name: name
        })
    }

    private handleOnClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        this.props.onClick(this.props.eventKey);
    }
}

export default UserEquipment;