import { ID } from '@datorama/akita';
import { JournalStore, journalStore } from './journal.store';
import {http} from "../../index";

export class JournalService {

  constructor(private journalStore: JournalStore) {
  }

  loadingJournal(id){
  http.get(`/api/registry/journal/${id}`)
      .then(response =>{
       journalStore.update(response.data)
      })
  }

}

export const journalService = new JournalService(journalStore);