import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import { User } from './auth.model';

export interface AuthState extends EntityState<User> {}

@StoreConfig({
  name: 'authorization_store',
  idKey: '_id',
  resettable: true
})

export class AuthStore extends EntityStore<AuthState> {

  constructor() {
    super();
  }

}

export const authStore = new AuthStore();

