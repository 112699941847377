import SimpleDateFormat from "@riversun/simple-date-format";

export function convertDateToFormat(date?: Date, format: string = "dd.MM.yyyy"): string|undefined {
  if(!date) {
    return undefined;
  }

  const sdf = new SimpleDateFormat();

  return sdf.formatWith(format, date)
}