import {ID, Store, StoreConfig} from '@datorama/akita';

export interface JournalState {
  id: ID;
  acceptor: string;
  date: Date;
  executor: string;
  repair_parts: string;
  review: Review;
  status: Status;
  verification_certificate: string;
  what_was_done: string;
}

export interface Review {
  createdAt: Date;
  equipment: Equipment;
  id: number;
  name: string;
  periodicity: Periodicity;
  type: TypeJob;
}

export interface Thumb {
  file_name: string;
  url: string;
}

export interface Category {
  id: number;
  name: string;
  thumb: Thumb;
}

export interface Equipment {
  category: Category;
  documentation: string;
  id: number;
  manufacturer: string;
  manufacturerDate: Date;
  name: string;
  periodicity: Periodicity;
  thumb: string;
}

export enum Periodicity {
  EVERY_MONTH = "EVERY_MONTH ", EVERY_QUARTER = "EVERY_QUARTER", EVERY_HALF_YEAR = "EVERY_HALF_YEAR", EVERY_YEAR = "EVERY_YEAR"
}

export enum TypeJob {
  MAINTENANCE = "MAINTENANCE", VERIFICATION = "VERIFICATION"
}

export enum Status {
  EXPIRED = "EXPIRED", DONE = "DONE", ASSIGNED = "ASSIGNED", PLANNED = "PLANNED"
  
}

export function createInitialState(): JournalState {
  return {
    acceptor: "",
    date: new Date,
    executor: "",
    id: 0,
    repair_parts: "",
    review: {
      createdAt: new Date,
      equipment: {
        category: {
          id: 0,
          name: "",
          thumb: {
            file_name: "",
            url: ""
          },
        },

        documentation: "",
        id: 0,
        manufacturer: "",
        manufacturerDate: new Date,
        name: "",
        periodicity: Periodicity.EVERY_MONTH,
        thumb: ""
      },
      id: 0,
      name: "",
      periodicity: Periodicity.EVERY_MONTH,
      type: TypeJob.MAINTENANCE,
    },
    status: Status.EXPIRED,
    verification_certificate: "",
    what_was_done: ""
  };
}

@StoreConfig({ name: 'journal', resettable: true })
export class JournalStore extends Store<JournalState> {

  constructor() {
    super(createInitialState());
  }

}

export const journalStore = new JournalStore();

