import React, {Component} from "react";
import {Nothing} from "../../models/Nothing";
import {Link, Redirect} from "react-router-dom";
import {AuthLogo, LoginForm} from "medissima-ui-kit";
import {authService} from "./auth.service";
import "../../css/Registration.css"

type State = {
  needRedirect: boolean,
  error?: string
}

class SignIn extends Component<Nothing, State> {


  constructor(readonly props: Nothing) {
    super(props);

    this.state = {
      needRedirect: false
    }
  }

  render() {
    if (this.state.needRedirect) {
      return <Redirect to={"dashboard"}/>
    }

    return <div
      className={'authorization-form--container'}
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + "auth_back.jpg"})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%"
      }}>
      <AuthLogo className={"auth--logo"}/>
      <LoginForm onFormSubmit={(email: string, password: string) => this.handleOnFormSubmit(email, password)}
                 error={this.state.error}
                 formTitle={"Добро пожаловать!"}/>
      <Link className={"authorization-form--link"} to={"/sign-up"}>Нет аккаунта? Создать</Link>
    </div>;
  }

  private handleOnFormSubmit(email: string, password: string) {
    authService.login(
      {
        email: email,
        password: password
      },
      (success: boolean) => this.setState({
        needRedirect: success
      }),
      (message: string) => this.setState({
        ...this.state,
        error: message
      })
    )
  }
}

export default SignIn