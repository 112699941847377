import React, {Component} from 'react';
import {Employee} from "./employee.model";
import {ID} from "@datorama/akita";
import {BaseCard, TextSize, DeleteIcon, Paragraph, SettingIcon, StarIcon} from "medissima-ui-kit";

type Data = {
    employee: Employee,
    isOwner: boolean,
    canEdit: boolean,
    canDelete: boolean,
    onDelete: (id: ID) => void
}

class EmployeesCard extends Component<Data> {
    static defaultProps = {
        admin: false
    };

    adminIcon(){
        if(this.props.employee.is_admin){
            return <div className={"admin-status"}>
                <StarIcon/>
                <p  style={{
                    color: "#40D094",
                    fontWeight:"bold",
                    alignItems:"center",
                    display:"flex",
                    cursor: "pointer"
                }}
                >
                    Администратор</p>
            </div>
        }

        if(!this.props.canEdit) {
            return null;
        }

        return <SettingIcon className={"setting-icon"}/>

    }

    render(){
        return <BaseCard className={"setting-card employees-card"}>
            <div className={"employees-data"}>
               <Paragraph color={"#303030"}  className={"employees-name"} text={this.props.employee.name}/>
               <Paragraph color={"#72AAFC"} size={TextSize.P_18} className={"employees-email"} text={this.props.employee.email}/>
               <Paragraph color={"#808080"} size={TextSize.P_18} className={"employees-phone"} text={this.props.employee.phone}/>
            </div>
            <div className={"employees-actions"}>
                {this.adminIcon()}
                { this.props.canDelete ? <DeleteIcon className={"delete-icon"} onClick={_ => this.props.onDelete(this.props.employee.id)}/> : null }
            </div>
        </BaseCard>
    }
}

export default  EmployeesCard;