import { QueryEntity } from '@datorama/akita';
import { UserEquipmentStore, UserEquipmentState, userEquipmentStore } from './user-equipment.store';

export class UserEquipmentQuery extends QueryEntity<UserEquipmentState> {

  constructor(protected store: UserEquipmentStore) {
    super(store);
  }

}

export const userEquipmentQuery = new UserEquipmentQuery(userEquipmentStore);
