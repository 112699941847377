import React, {Component} from 'react';
import {toast} from "react-toastify";
import {TextSize, BaseCard, ButtonComponent, Paragraph, PasswordInput} from "medissima-ui-kit";

type Data = {
    isDisabled?: boolean,
    onSubmit: (oldPass: string, newPass: string) => void
}

type State = {
    oldPass: string,
    newPass: string
}

class ChangePassword extends Component<Data, State> {

    constructor(props: Readonly<Data>) {
        super(props);

        this.state = {
            oldPass: "",
            newPass: ""
        }
    }

    render(){
        return <BaseCard className={"setting-card"}>
            <div className={"setting-card--head"}>
                <Paragraph color={"#505050"} size={TextSize.P_18} text={"Сменить пароль"}/>
            </div>
            <div className={"setting-card--password"}>
                <PasswordInput placeholder={"Введите старый пароль"} id={"some-id"} onInputChange={input => this.setState({...this.state, oldPass: input})}/>
                <PasswordInput placeholder={"Введите новый пароль"} id={"some-id"} onInputChange={input => this.setState({...this.state, newPass: input})}/>
            </div>
            <ButtonComponent background={"#40D094"} isDisabled={this.props.isDisabled} text={"Применить"} onClick={()=> {
                const {oldPass, newPass} = this.state;
                this.props.onSubmit(oldPass, newPass);
                toast("Пароль успешно изменен", {autoClose: 1500});
            }}/>
        </BaseCard>
    }
}

export default ChangePassword;