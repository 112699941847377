import React, {Component} from 'react';
import NotificationCard from "./NotificationCard";
import {Nothing} from "../../../models/Nothing";
import {notificationsService} from "./notifications.service";
import {notificationsQuery} from "./notifications.query";
import {NotificationItem} from "./notification.model";
import {ButtonComponent, H2, IconButton, Paragraph} from "medissima-ui-kit";

type State = {
    list: Array<NotificationItem>,
    switchAll: boolean
}

class NotificationSettings extends Component<Nothing, State> {

    constructor(props: Readonly<Nothing>) {
        super(props);

        this.state = {
            list: [],
            switchAll: true
        }
    }

    componentDidMount(): void {
        notificationsService.loadAllNotifications();

        notificationsQuery
            .selectAll()
            .subscribe(it => {
                this.setState({
                    list: it
                })
            })
    }

    buttonChangeMethod(){
        if(this.state.switchAll){
            return <ButtonComponent className={"button-enable-all"} icon={IconButton.NOTIFICATION} background={"#40D094"} text={"Включить все"} onClick={() => this.handleOnSwitchAll()}/>
        }
        else {
            return <ButtonComponent icon={IconButton.NOTIFICATION} background={"#FC8080"} text={"Отключить все"} onClick={() => this.handleOffSwitchAll()}/>
        }
    }

    render(){
        return <div className={"notification-settings"}>
            <div className={"notification-head"}>
                <div className={"notification-title"}>
                    <H2 color={"#303030"} text={"Оповещения"}/>
                    <Paragraph text={"Настройте оповещения и получайте уведомления только о тех событиях, которые вам интересны!"}
                               color={"#607088"}
                    />
                </div>
                <div className={"enable-all"}>
                    {this.buttonChangeMethod()}
                </div>
            </div>
            <div className={"notification-body"}>
                {
                    this.state.list.map(it => {
                        return <NotificationCard textCard={it.name} isEnabled={it.enabled} id={it.id?.toString()} handleOnSwitch={event => this.handleOnSwitchEvent(event)}/>
                    })
                }
            </div>
        </div>
    }

    private handleOnSwitchEvent(event: React.ChangeEvent<HTMLInputElement>) {
        notificationsService.updateNotificationById(event.target.id)
    }

    private handleOnSwitchAll() {
        notificationsService.turnOnAllNotifications();
        this.setState({
            switchAll: false
        })
    }

    private handleOffSwitchAll() {
        notificationsService.turnOffAllNotifications();
        this.setState({
            switchAll: true
        })
    }
}

export default NotificationSettings;