import { ID } from '@datorama/akita';
import { PaginationStore, paginationStore, createInitialState} from './pagination.store';

export class PaginationService {

  constructor(private paginationStore: PaginationStore) {
  }
  reset(){
    paginationStore.update(createInitialState())
  }

  setPaging(total: number, current: number, limit: number){
    paginationStore.update({
      current: current,
      total: total,
      page_count: parseInt(total / limit + "")
    })
  }

}

export const paginationService = new PaginationService(paginationStore);