import React, {Component} from "react";
import "../css/Modals.css"
import {H2, Paragraph, TextSize, RowComponent, SettingIcon, TextInput, Weight, CalendarIcon, ColorField, StyledFileInput} from "medissima-ui-kit";
import {convertDateToFormat} from "../../../date";
import Calendar from 'react-calendar'
import {fileService, UploadData} from "../../file/file.service";
import {ButtonComponent, EditIcon} from "medissima-ui-kit/lib";
import {ID} from "@datorama/akita";
import {EquipmentProfileState} from "../../user_equipment/equipment_profile/equipment-profile.store";
import {equipmentProfileService} from "../../user_equipment/equipment_profile/equipment-profile.service";
import {equipmentProfileQuery} from "../../user_equipment/equipment_profile/equipment-profile.query";
import {Input} from "reactstrap";

type Data = {
    id:ID
}

type State= {
    uploadedAct?: UploadData,
    uploadedInstruction?: UploadData,
    fileNameAct?: string,
    fileNameInstruction?: string,
    date?: Date
    isPickerService?: boolean,
    dateEntryIntoService: Date,
    serial: string,
    equipmentState?: EquipmentProfileState,
    address: string,
    value?: string,
    editAddress: boolean
}

class EditEquipmentModal extends Component<Data, State> {
    constructor(props: Readonly<Data>) {
        super(props);
        this.state = {
            isPickerService: false,
            dateEntryIntoService: new Date(),
            serial: "",
            address: "",
            editAddress: false

        }
    }

    componentDidMount(): void {
        equipmentProfileService.getEquipmentItem(this.props.id)
        equipmentProfileQuery.select()
            .subscribe(item => {
                this.setState({
                    ...this.state,
                    equipmentState: item,
                    dateEntryIntoService: new Date(item.details.entry_into_service),
                    serial: item.details.serial ?? "",
                    address: item.address ?? ""
                })
            })
    }

    render() {
        return <div className={"modal--service"}>
            <div className={"modal--service-title"}>
                <SettingIcon fill={"#72AAFC"} height={"32px"} width={"32px"}/>
                <div>
                    <H2 text="Редактирование информации об оборудовании" color={"#303030"}/>
                </div>
            </div>
            <div className={"modal--service-journal"}>
                <RowComponent>
                <Paragraph text={"Адрес"} color={"#607088"} size={TextSize.P_18}/>
                <div className={"modal--service-address"}>
                     {this.getContent()}
                    <EditIcon onClick={event => this.editAddress(event)}/>
                </div>
            </RowComponent>
                <RowComponent>
                    <Paragraph text={"Введен в эксплуатацию"} color={"#607088"} size={TextSize.P_18}/>
                    <div className={"modal--service-period"}>
                        <Paragraph text={convertDateToFormat(new Date(this.state.dateEntryIntoService))} color={"#607088"} size={TextSize.P_18} fontWeight={Weight.BOLD}/>
                        <div className={"date-picker"}>
                            <CalendarIcon
                                onClick={() => this.handleOnClickEntryIntoService()}
                            />
                            <Calendar
                                className={this.state.isPickerService ? "": "hidden"}
                                onChange={(date) => this.onDateEntryIntoService(date)}
                                value={this.state.dateEntryIntoService ?? new Date()}
                            />
                            <Paragraph text={"Выбрать"} color={"#607088"} size={TextSize.P_18}/>
                        </div>
                    </div>
                </RowComponent>

                <RowComponent>
                    <Paragraph color={"#607088"} text={"Серийный номер"} size={TextSize.P_18}/>
                      <TextInput id={"serial-number"} value={this.state.serial} placeholder={"Введите серийный номер оборудования"} onInputChange={(serial:string) => {this.onChangeInputSerial(serial)}}/>
                </RowComponent>
            </div>
            <div className={"modal--service-upload"}>
                <div className={"uploaded"}>
                    <StyledFileInput text={this.state?.fileNameInstruction ?? "Приложить инструкцию"}
                                     id={"edit-category-instruction"}
                                     onFileChanged={(file?: File|null) => this.onInstructionUploaded(file)}
                                     iconFile={true}
                    />
                    <StyledFileInput text={this.state?.fileNameAct ?? "Акт ввода в эксплуатацию"}
                                     id={"edit-category-act"}
                                     onFileChanged={(file?: File|null) => this.onActUploaded(file)}
                                     iconFile={true}
                    />
                </div>
                <ButtonComponent background={"#40D094"} text={"Сохранить"} onClick={() => this.onFormSubmit()}/>
            </div>
        </div>
    }


   private onInstructionUploaded(file?: File|null) {
        if(file !== undefined && file !== null) {
            fileService.uploadFile(file)
                .then(data => {
                    this.setState({
                        uploadedInstruction: data
                    })
                })
        }

       this.setState({
           fileNameInstruction: file?.name
       })
    }

    private onActUploaded(file?: File|null) {
        if(file !== undefined && file !== null) {
            fileService.uploadFile(file)
                .then(data => {
                    this.setState({
                        uploadedAct: data
                    })
                })
        }
        this.setState({
            fileNameAct: file?.name
        })
    }

    private onChangeInputSerial(serial: string) {
        this.setState({serial: serial});
    }

    private onFormSubmit() {
        equipmentProfileService.updateEquipment({
            id: Number(this.props.id),
            serial: this.state.serial,
            address: this.state.address,
            instruction: this.state.uploadedInstruction?.file_name ?? "",
            act: this.state.uploadedAct?.file_name ?? "",
            installedAt: this.state.dateEntryIntoService
        })
    }

    private onDateEntryIntoService(date: Date) {
        this.setState({
            ...this.state,
            dateEntryIntoService: date,
            isPickerService: false
        })
    }

    private handleOnClickEntryIntoService(){
            this.setState({
                ...this.state,
                isPickerService: !this.state.isPickerService
            })
    }

    private getContent() {
        if(this.state.editAddress) {
            return <Input
                type={"text"}
                onKeyUp={(event) => this.handleOnKeyUp(event) }
                onChange={ event => {
                    event.preventDefault();
                    this.setState({...this.state, address: event.target.value})
                }
                }
                placeholder={this.state.address !== "" ? this.state.address : "Введите адрес"}
            />
        }

        return <Paragraph text={this.state.address !== "" ? this.state.address :  "Адрес не указан"} color={"#607088"} size={TextSize.P_18} fontWeight={Weight.BOLD} />;
    }

    private editAddress(event: React.MouseEvent){
        event.preventDefault()
        this.setState({
            editAddress: !this.state.editAddress
        })
    }

    private handleOnKeyUp(event: React.KeyboardEvent){
        if(event.keyCode == 13){
            this.setState({
                editAddress: !this.state.editAddress
            })
        }
    }
}

export default EditEquipmentModal;