import {CategoryStore, categoryStore} from './category.store';
import {http} from "../../../index";
import {Category} from "./category.model";
import {ModalsStateType, modalsStore} from "../../modals/state/modals.store";
import {Nothing} from "../../../models/Nothing";
import {ResponseWrapper} from "medissima-http-client/lib";


export class CategoryService {

  constructor(private categoryStore: CategoryStore) {
  }

  getCategoryList() {
    categoryStore.setLoading(true);

     http.get<Category[]>("api/categories")
        .then(response => {
            response.data.map(item => {
              categoryStore.upsert(item.id, item)
            });
        })
        .catch(reason => {
            modalsStore.update({
                key: ModalsStateType.ERROR,
                payload: {
                    error: (reason as ResponseWrapper<Nothing>).message
                }
            })
        })
        .finally(() =>{categoryStore.setLoading(false)})
  }

    loadCategoryForMenu():Promise<Category[]>{
        return http.get<Category[]>(`/api/categories/my`)
            .then(response => {
                return response.data
            })
    }
}

export const categoryService = new CategoryService(categoryStore);