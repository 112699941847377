import { ID } from '@datorama/akita';
import { EmployeesStore, employeesStore } from './employees.store';
import {createEmployee, Employee} from "./employee.model";
import {toast} from "react-toastify";
import {http} from "../../../index";

export class EmployeesService {

  constructor(private employeesStore: EmployeesStore) {
  }

  loadingEmployees(){
    ["/api/employers/admins", "/api/employers/"].forEach(endpoint => {
      http.get<Employee[]>(endpoint)
      .then(response => {
        response.data.map(item => {
          this.storeEmployer(item);
        })
      })
    })
  }

  private storeEmployer(item: Employee) {
    employeesStore.upsert(item.id, createEmployee({
      id: item.id,
      name: item.name,
      email: item.email,
      phone: item.phone,
      is_admin: item.is_admin || item.is_super_admin,
      is_super_admin: item.is_super_admin
    }))
  }

  deleteUser(id: ID) {
    http.rdelete<boolean>(`/api/employers/${id}`)
        .then(response => {
          if(response.data) {
            employeesStore.remove(id);
            toast("Пользователь успешно удален", {autoClose: 1500});
          }
        });

  }
}

export const employeesService = new EmployeesService(employeesStore);