import React, {Component} from "react";
import {CircularProgressbarWithChildren} from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';
import {BaseCard, GradientLinear, H2, ListItem, ListItemBorder} from "medissima-ui-kit";
import {ModalsStateType, modalsStore} from "../../modals/state/modals.store";

type Data = {
    expired: number,
    processed: number,
    total: number,
    expiredList?: Array<any>
}

class StateCard extends Component<Data> {
    render() {
        return <BaseCard className={"state-card"}>
            <H2 text={"Состояние"}/>
            <div className={"progressbar-web"}>
                <GradientLinear id={"progressbar_web"} startColor={"#41D194"} endColor={"#62E898"}/>
                <CircularProgressbarWithChildren
                    value={this.props.processed}
                    maxValue={this.props.total}
                    className={"progressbar--circular-web"}
                    styles={{
                        path: {
                            stroke: `rgba(98, 232, 152, ${this.props.processed / this.props.total})`,
                            strokeLinecap: 'butt',
                            transition: 'stroke-dashoffset 0.5s ease 0s',
                            transform: 'rotate(-0.25turn)',
                            transformOrigin: 'center center'
                        },
                        trail: {
                            stroke: '#EAF2FC',
                            strokeLinecap: 'butt',
                            transform: 'rotate(-0.25turn)',
                            transformOrigin: 'center center'
                        }
                    }}
                >
                    <div className={"progress-value"}>
                        <p><span>{this.props.processed}</span>/{this.props.total}</p>
                    </div>
                </CircularProgressbarWithChildren>
            </div>

            <p className={"expired"} style={{color: "#303030", fontSize: "36px"}}>
                Просрочено <span style={{fontWeight: "bold", fontSize:"24px"}}>({this.props.expired})</span>
            </p>
            <ul className={"state-card--list card-list"}>
                {this.props.expiredList?.map(i => {
                    return <ListItem className={"state-card--list-item marker-chevron"}
                                     text={i.name}
                                     background={"#F8F8F8"}
                                     border={ListItemBorder.BORDER_BROWN}
                                     onClick={() => this.handleOnClick(i.id)}
                    />
                })}
            </ul>
        </BaseCard>
    }
    private handleOnClick(id){
        modalsStore.update({
            key: ModalsStateType.SERVICE,
            payload: {
                id: id
            }
        })
    }
}

export default StateCard;