import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Registration } from './registration.model';

export interface RegistrationState extends EntityState<Registration> {}

@StoreConfig({
  name: 'registration',
  idKey: '_id'
})
export class RegistrationStore extends EntityStore<RegistrationState> {

  constructor() {
    super();
  }

}

export const registrationStore = new RegistrationStore();

