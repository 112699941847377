import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Schedule } from './schedule.model';

export interface ScheduleState extends EntityState<Schedule> {}

@StoreConfig({
  name: 'schedule',
  idKey: '_id',
  resettable: true
})
export class ScheduleStore extends EntityStore<ScheduleState> {

  constructor() {
    super();
  }

}

export const scheduleStore = new ScheduleStore();

