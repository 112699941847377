import { QueryEntity } from '@datorama/akita';
import { CategoryStore, CategoryState, categoryStore } from './category.store';

export class CategoryQuery extends QueryEntity<CategoryState> {

  constructor(protected store: CategoryStore) {
    super(store);
  }

}

export const categoryQuery = new CategoryQuery(categoryStore);
