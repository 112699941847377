import { ID } from '@datorama/akita';
import {EquipmentItemState, EquipmentItemStore, equipmentItemStore} from './equipment-item.store';
import {http} from "../../../index";
import {ModalsStateType, modalsStore} from "../../modals/state/modals.store";
import {Nothing} from "../../../models/Nothing";
import {ResponseWrapper} from "medissima-http-client/lib";

export class EquipmentItemService {

  constructor(private equipmentItemStore: EquipmentItemStore) {
  }

  getEquipmentItems(id: ID){
    equipmentItemStore.setLoading(true)

    return http.get<EquipmentItemState>(`/api/catalog/view/${id}`)
        .then(response => {
          equipmentItemStore.update(response.data)
        })
        .catch(reason =>{
          modalsStore.update({
            key: ModalsStateType.ERROR,
            payload: (reason as ResponseWrapper<Nothing>).message
          })
        })
        .finally(() => {
          equipmentItemStore.setLoading(false)
        })
  }


}

export const equipmentItemService = new EquipmentItemService(equipmentItemStore);