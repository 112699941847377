import React, {Component} from 'react';
import {BaseCard, NotifOff, NotifOn, Paragraph, SwitchButton} from "medissima-ui-kit";

type Data = {
    textCard?: string,
    isEnabled: boolean,
    id: string,
    handleOnSwitch: (event: React.ChangeEvent<HTMLInputElement>) => void
}

class NotificationCard extends Component<Data> {

    iconNotification(){
        if(this.props.isEnabled) {
            return <NotifOn />
        }

        return <NotifOff />
    }

    render(){
        return <BaseCard className={"setting-card notification-card"}>
                <div className={"notification-text"}>
                    {this.iconNotification()}
                    <Paragraph className={!this.props.isEnabled ? "is-disabled" : undefined} text={this.props.textCard} color={"#303030"} />
                </div>
                <SwitchButton isDisabled={this.props.isEnabled} id={this.props.id} onChange={event => this.props.handleOnSwitch(event)}/>
        </BaseCard>
    }
}

export default NotificationCard;