import React, {Component} from "react";
import "../css/Modals.css"
import {H2} from "medissima-ui-kit";
import {AddEquipmentField, DropDownComponent, SearchInput} from "medissima-ui-kit/";
import {Category} from "../../catalog/category_state/category.model";
import {Equipment} from "../../catalog/equipment_state/equipment.model";
import {categoryService} from "../../catalog/category_state/category.service";
import {categoryQuery} from "../../catalog/category_state/category.query";
import {NavigationState, navigationStore} from "../../user_equipment/nav_store/navigation.store";
import {navigationQuery} from "../../user_equipment/nav_store/navigation.query";
import {equipmentQuery} from "../../catalog/equipment_state/equipment.query";
import {equipmentService} from "../../catalog/equipment_state/equipment.service";
import {userEquipmentService} from "../../user_equipment/equipment_state/user-equipment.service";

type State = {
    categoryList: Category[]
    equipmentList: Equipment[],
    reset?: boolean,
    value?: string
}

class CatalogModal extends Component<any, State> {
    constructor(props: Readonly<any>) {
        super(props);
        this.state = {
            categoryList: [],
            equipmentList: []
        }
    }

    componentDidMount(): void {
        categoryService.getCategoryList()
        equipmentService.getAllEquipment()

        categoryQuery.selectAll()
            .subscribe(items => {
                this.setState({
                    ...this.state,
                    categoryList: items
                })
            })


        navigationQuery.select()
            .subscribe((state: NavigationState) => {
               let selectEquipment = equipmentQuery.getAll()
                    .filter(i => i.category == state.categoryInModal)
               this.setState({
                   ...this.state,
                   equipmentList: selectEquipment
               })
            })
    }

    render() {
        return <div className={"modal--catalog"}>
            <H2 className={"modal--catalog-title"} text={"Добавить оборудование"} color={"#303030"}/>
            <div className={"row modal--catalog-search"}>
                <div className={"col-md-6"}>
                    <SearchInput onTextInput={(text) => this.handleOnSearch(text) }
                                 value={this.state.value}
                    />
                </div>
                <div className={"col-md-6"}>
                    <DropDownComponent id={"2"}
                                       onItemSelected={(_: number, value: string) => this.selectCategory(value)}
                                       placeholder={"Выбрать из каталога"}
                                       items={this.state.categoryList.map(i => i.name)}
                                       reset={this.state.reset}

                    />
                </div>
            </div>
            <div className={"modal--catalog-list"}>
                {this.state.equipmentList.map(i => {
                    return  <AddEquipmentField text={i.name}
                                               onClick={(id) => this.onFormSubmit(i.id)}

                    />
                })}
            </div>
        </div>
    }

    private selectCategory(name){
        navigationStore.update({
            categoryInModal: name
        })
        this.setState({
            reset: false,
            value: ""
        })
    }

    private onFormSubmit(id){
        userEquipmentService.addEquipmentToCompany(id)
    }

    private handleOnSearch(text){

       if(text.length >= 1){
           let searchItem = equipmentQuery.getAll()
               .filter(i => {
                   return i.name.toLowerCase().includes(text)
               })
           this.setState({
               ...this.state,
               equipmentList: searchItem,
               reset: true,
               value: undefined
           })
       } else {
           this.setState({
               ...this.state,
               equipmentList: []
           })
       }
    }
}

export default CatalogModal;