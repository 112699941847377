import React, {Component} from 'react';
import {Input} from "reactstrap";
import { toast } from 'react-toastify';
import {TextSize, AvatarIcon, BaseCard, CheckIcon, EditIcon, Paragraph, SwitchButton, WarningIcon} from "medissima-ui-kit";

type Data = {
    textCard?: string,
    titleCard?: string,
    requiredConfirmation?: boolean,
    textConfirmation?: string,
    icon?: IconType,
    edit?: EditingType,
    onDataChanged?: (value?: string) => void,
    onConfirmClicked?: () => void,
    switchButton?: boolean,
    id?: string,
    isEnabled?: boolean
}

type State = {
    isEdit: boolean,
    value?: string
}

export enum IconType {
    AVATAR, CHECK, WARNING
}

export enum EditingType {
    ICON, TEXT
}

class ProfileSettingsCard extends Component<Data, State> {
    static defaultProps = {
        requiredConfirmation: false
    };

    constructor(props: Readonly<Data>) {
        super(props);

        this.state = {
            isEdit: false
        }
    }
    notifySettings = () => toast("Настройки сохранены", {autoClose: 1700});

    iconType(){
        if(this.props.textCard === undefined) {
            return null;
        }

        let result;
        switch (this.props.icon) {
            case IconType.AVATAR:
                result = <AvatarIcon fill={"#C8D8EE"} width={"32px"} height={"32px"} />;
                break;
            case IconType.CHECK:
                result = <CheckIcon />;
                break
            case IconType.WARNING:
                result = <WarningIcon />;
                break;
        }

        return result;
    }

    editingType(){
        let result;
        switch (this.props.edit) {
            case EditingType.ICON:
                result = <EditIcon onClick={event => this.handleOnChangeClick(event)} />;
                break;
            case EditingType.TEXT:
                result = <a style={{fontSize: "14px"}} onClick={event => this.handleOnChangeClick(event)}>Изменить</a>;
                break
        }
        return result;

    }

    switchButton(){
        if(this.props.switchButton){
            return <SwitchButton isDisabled={this.props.isEnabled} id={this.props.id ?? "id-"}/>
        }
    }

    render(){
        return <BaseCard className={"setting-card"}>
            <div className={"setting-card--head"}>
                <Paragraph color={"#505050"} size={TextSize.P_18} text={this.props.titleCard}/>
                {this.editingType()}
            </div>
            <div className={"setting-card--body"}>
                {this.iconType()}
                {this.getContent()}
                {this.switchButton()}
            </div>
            <div className={"setting-card--confirmation"}
                 style={{display: `${!this.props.requiredConfirmation ? "block" : "none"}`}}
            >
                <a style={{color: "#40D094"}} onClick={event => this.handleOnConfirmLinkClick(event)}>{this.props.textConfirmation}</a>
            </div>
        </BaseCard>
    }

    private getContent() {
        if(this.state.isEdit) {
            return <Input
                onKeyUp={(event => this.handleOnKeyUp(event))}
                type={"text"}
                onChange={ event => {
                    event.preventDefault();
                    this.setState({...this.state, value: event.target.value})
                    }
                }
                value={this.state.value ?? this.props.textCard}
                placeholder={"Не указано"}
            />
        }

        return <Paragraph color={"#303030"} text={this.state.value ?? this.props.textCard ?? "Не указано"}/>;
    }

    private handleOnChangeClick(event: React.MouseEvent) {
        event.preventDefault();

        if(this.state.isEdit) {
            if(this.props.onDataChanged !== undefined) {
                this.props.onDataChanged(this.state.value);
                this.notifySettings()
            }
        }

        this.setState({
            isEdit: !this.state.isEdit
        })
    }

    private handleOnConfirmLinkClick(event: React.MouseEvent<HTMLAnchorElement>) {
        event.preventDefault();

        if(this.props.onConfirmClicked) {
            this.props.onConfirmClicked()
        }
    }

    private handleOnKeyUp(event: React.KeyboardEvent){
        if(event.keyCode == 13){
            if(this.props.onDataChanged !== undefined) {
                this.props.onDataChanged(this.state.value);
                this.notifySettings()
            }
            this.setState({
                isEdit: !this.state.isEdit
            })
        }
    }
}

export default ProfileSettingsCard;