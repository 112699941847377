import { ID } from '@datorama/akita';

export interface NotificationItem {
  id: ID;
  name: string,
  slug: string,
  enabled: boolean
}

export interface NotificationServerType {
  notification: {
    id: number;
    name: string,
    slug: string
  },
  enabled: boolean
}

export interface NotificationType {
  id: number;
  name: string,
  slug: string
}

export function createNotification(params: Partial<NotificationItem>) {
  return {
    ...params
  } as NotificationItem;
}
