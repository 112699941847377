import React, {Component} from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {ButtonComponent, PageHeading, PhotoArea, Weight} from "medissima-ui-kit/";
import EquipmentSpecifications from "./EquipmentSpecifications";
import {EquipmentItemState, createInitialState, equipmentItemStore} from "./equipment-item/equipment-item.store";
import {equipmentItemService} from "./equipment-item/equipment-item.service";
import {equipmentItemQuery} from "./equipment-item/equipment-item.query";

class EquipmentInfoPage extends Component<any, EquipmentItemState> {
    constructor(props: Readonly<any>) {
        super(props);
        this.state = createInitialState()

        equipmentItemService.getEquipmentItems(props.match.params.id)
    }

    componentDidMount(): void {
        equipmentItemStore.reset()

        equipmentItemQuery.select()
            .subscribe(obj => {
                this.setState(obj)
            })
    }

    render(){
        return <div className={"view-container"}>
            <Breadcrumb className={"bread-crumb--component"}>
                <Breadcrumb.Item href={"/#/dashboard"}>Личный кабинет</Breadcrumb.Item>
                <Breadcrumb.Item href={"/#/catalog"}>Мое оборудоание</Breadcrumb.Item>
                <Breadcrumb.Item active>{this.state.name}</Breadcrumb.Item>
            </Breadcrumb>
            <PageHeading title={this.state.name} onBack={() => {this.props.history.goBack()}}>
                <ButtonComponent background={"#40D094"}
                                 text={"Запросить коммерческое предложение"}
                                 onClick={() => {}}
                                 fontWeight={Weight.BOLD}
                />
            </PageHeading>
            <div className={"row equipment-info--body"}>
                <div className={"col-md-6"}>
                    <PhotoArea photo={this.state.thumb?.url ?? process.env.PUBLIC_URL + "equipment.png"} onError={process.env.PUBLIC_URL + "equipment.png"}/>
                </div>
                <div className={"col-md-6"}>
                    <EquipmentSpecifications fields={[
                        {
                            name: "Категория",
                            value: this.state.category.name
                        },
                        {
                            name: "Дата выпуска",
                            value: this.state.manufacturer
                        },
                        {
                            name: "Документация",
                            value: this.state.documentation
                        }
                    ]}
                    />
                </div>
            </div>
        </div>
    }
}

export default EquipmentInfoPage;