import React, {Component} from 'react';
import ProfileSettingsCard, {EditingType, IconType} from "./ProfileSettingsCard";
import ChangePassword from "./ChangePassword";
import {Nothing} from "../../../models/Nothing";
import {createInitialState, ProfileState} from "./profile.store";
import {profileQuery} from "./profile.query";
import {profileService} from "./profile.service";
import {H2} from "medissima-ui-kit";

class BasicSettings extends Component<Nothing, ProfileState> {

    constructor(props: Readonly<Nothing>) {
        super(props);

        this.state = createInitialState()
    }

    componentDidMount(): void {
        profileQuery.select()
            .subscribe(state => {
                console.log(state);
                this.setState(state)
            })
    }

    render(){
        return <div className={"row basic-settings"}>
                <div className={"col col-sm-6"}>
                    <H2 className={"title"} color={"#303030"} text={"Основные настройки"}/>
                    <ProfileSettingsCard
                        titleCard={"Название организации"}
                        textCard={this.state.organizationName}
                        edit={EditingType.TEXT}
                        onDataChanged={value => profileService.updateCompanyName(value)}
                    />
                    <ProfileSettingsCard
                        titleCard={"Мое имя"}
                        textCard={this.state.name}
                        icon={IconType.AVATAR}
                        edit={EditingType.ICON}
                        onDataChanged={value => profileService.updateUserName(value)}
                    />
                    <ProfileSettingsCard
                        titleCard={"Контактный Email"}
                        icon={this.state.email?.confirmed ? IconType.CHECK : IconType.WARNING}
                        textCard={this.state.email?.value}
                        requiredConfirmation={this.state.email?.confirmed}
                        textConfirmation={"Подтвердить Email"}
                        edit={EditingType.ICON}
                        onConfirmClicked={() => profileService.verifyEmail()}
                        onDataChanged={value => profileService.updateEmail(value)}
                    />
                    <ProfileSettingsCard
                        titleCard={"Номер телефона"}
                        icon={this.state.phone?.confirmed ? IconType.CHECK : IconType.WARNING}
                        textCard={this.state.phone?.value}
                        requiredConfirmation={this.state.phone?.confirmed}
                        textConfirmation={"Подтвердить телефон"}
                        onConfirmClicked={() => profileService.verifyPhone()}
                        edit={EditingType.ICON}
                        onDataChanged={value => profileService.updatePhone(value)}
                    />
                    <ProfileSettingsCard
                        titleCard={"Адрес"}
                        textCard={this.state.address}
                        edit={EditingType.ICON}
                        onDataChanged={value => profileService.updateAddress(value)}
                    />
                </div>
                <div className={"col col-sm-6"}>
                    <H2 className={"title"} color={"#303030"} text={"Настройки безопасности"}/>
                    <ChangePassword onSubmit={(oldPass, newPass) => profileService.changePassword(oldPass, newPass)}/>
                </div>
            </div>

    }
}

export default BasicSettings;