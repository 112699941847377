import React, {Component} from "react";
import {Nothing} from "../../models/Nothing";
import RegistrationPassword from "./RegistrationPassword";
import RegistrationName from "./RegistrationName";
import RegistrationEmail from "./RegistrationEmail";
import RegistrationOrganization from "./RegistrationOrganization";
import {Link, Redirect} from "react-router-dom";
import {AuthLogo} from "medissima-ui-kit";

type State = {
  step: Steps,
  needRedirect: boolean,
  requestId?: string
}

enum Steps {
  NAME, EMAIL, PASSWORD, ORG
}

class SignUp extends Component<Nothing, State> {

  constructor(props: Readonly<Nothing>) {
    super(props);

    this.state = {
      step: Steps.NAME,
      needRedirect: false
    }
  }


  private determineStep() {
    switch (this.state.step) {
      case Steps.NAME:
        return <RegistrationName onFormSubmit={requestId => this.handleOnFormSubmit(Steps.EMAIL, requestId)} />;
  case Steps.EMAIL:
        return <RegistrationEmail requestId={this.state?.requestId} onFormSubmit={requestId => this.handleOnFormSubmit(Steps.PASSWORD, requestId)}/>;
      case Steps.PASSWORD:
        return <RegistrationPassword requestId={this.state?.requestId} onFormSubmit={requestId => this.handleOnFormSubmit(Steps.ORG, requestId)}/>;
      case Steps.ORG:
        return <RegistrationOrganization requestId={this.state?.requestId} onFormSubmit={() => this.finishRegistration()}/>;
    }
  }

  render() {
    if (this.state.needRedirect) {
      return <Redirect to={"dashboard"}/>
    }

    return <div
        className={'authorization-form--container'}
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL + "auth_back.jpg"})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%"
        }}>
      <AuthLogo className={"auth--logo"}/>
      {this.determineStep()}
      <Link className={"authorization-form--link"} to={"/sign-in"}>Уже зарегестрированы? Войти</Link>
    </div>;
  }

  private handleOnFormSubmit(step: Steps, requestId?: string) {
    this.setState({
      ...this.state,
      step: step,
      requestId: requestId
    });
  }

  private finishRegistration() {
    this.setState({
      needRedirect: true
    });
  }
}

export default SignUp