import {ID, Store, StoreConfig} from '@datorama/akita';
import {Category} from "../category_state/category.model";

export interface EquipmentItemState {
  id: ID;
  category: Category;
  documentation: string;
  name: string;
  periodicity: Periodicity;
  thumb?: Thumb;
  created_at: Date;
  updated_at: Date;
  manufacturer: string;
  manufacturer_date: Date;
}

export interface Thumb {
  file_name: string,
  url: string
}

export function createInitialState(): EquipmentItemState {
  return {
    id: 0,
    name: "",
    category: {
      id: 0,
      name: "",
      thumb: {
        file_name: "",
        url: ""
      }
    },
    documentation: "",
    created_at: new Date(),
    updated_at: new Date(),
    periodicity: Periodicity.EVERY_MONTH,
    thumb: {
      file_name: "",
      url: ""
    },
    manufacturer: "",
    manufacturer_date: new Date(),
  };
}

export enum Periodicity {
  EVERY_MONTH="EVERY_MONTH", EVERY_QUARTER="EVERY_QUARTER", EVERY_HALF_YEAR="EVERY_HALF_YEAR", EVERY_YEAR="EVERY_YEAR"
}

@StoreConfig({ name: 'equipment-item', resettable:true })
export class EquipmentItemStore extends Store<EquipmentItemState> {

  constructor() {
    super(createInitialState());
  }

}

export const equipmentItemStore = new EquipmentItemStore();

