import {ID} from '@datorama/akita';
import {NotificationsStore, notificationsStore} from './notifications.store';
import {createNotification, NotificationItem, NotificationServerType, NotificationType} from "./notification.model";
import {notificationsQuery} from "./notifications.query";
import {http} from "../../../index";

export class NotificationsService {

  constructor(private notificationsStore: NotificationsStore) {
  }

  loadAllNotifications() {
    http.get<NotificationType[]>("/api/notifications/list")
    .then(notificationsList => http.get<NotificationServerType[]>("/api/notifications/user-notifications")
        .then(userNotifications => {
          return {notificationsList: notificationsList.data, userNotifications: userNotifications.data}
        })
    )
    .then(result => {
      const {notificationsList, userNotifications} = result;

      console.log(result);

      notificationsList.map(notification => {
        const userNotification = userNotifications.find(i => i.notification.id == notification.id);

        return createNotification({
          id: notification.id,
          name: notification.name,
          slug: notification.slug,
          enabled: userNotification?.enabled ?? false,
        })
      })
      .map(i => {
        notificationsStore.upsert(i.id, i)
      });
    })
  }

  updateNotificationById(id: string) {
    const entity = notificationsQuery.getEntity(id);

    notificationsStore.upsert(entity.id, {
      enabled: !entity.enabled
    });

    this.updateNotifications([{type: entity.slug, enabled: !entity.enabled}]);
  }

  private updateNotifications(notifications: {type: string, enabled: boolean}[]) {
    http.post<NotificationItem[]>("/api/notifications/change", notifications)
    .then(response => {
      console.log(response);
    });
  }

  turnOnAllNotifications() {
    const notifications = notificationsQuery.getAll()
    .map(item => {
      notificationsStore.upsert(item.id, {
        enabled: true
      });

      return {
        type: item.slug,
        enabled: true
      }
    });

    this.updateNotifications(notifications)
  }

  turnOffAllNotifications() {
    const notifications = notificationsQuery.getAll()
    .map(item => {
      notificationsStore.upsert(item.id, {
        enabled: false
      });

      return {
        type: item.slug,
        enabled: false
      }
    });

    this.updateNotifications(notifications)
  }
}

export const notificationsService = new NotificationsService(notificationsStore);