import React, {Component} from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import "../../css/Equipment.css"
import EquipmentsList from "./components/EquipmentsList";
import {Align, ButtonComponent, Center, Loader, PageHeading, Paragraph, Weight} from "medissima-ui-kit";
import {entitiesToArray, ID} from "@datorama/akita";
import {ModalsStateType, modalsStore} from "../modals/state/modals.store";
import {UserEquipment} from "./equipment_state/user-equipment.model";
import {userEquipmentService} from "./equipment_state/user-equipment.service";
import {userEquipmentQuery} from "./equipment_state/user-equipment.query";
import {NavigationState} from "./nav_store/navigation.store";
import {navigationQuery} from "./nav_store/navigation.query";
import {SearchInput} from "medissima-ui-kit/lib";


type State = {
    equipmentList: UserEquipment[],
    isEmpty: boolean,
    isLoading: boolean,
    navigation?: NavigationState
}

class UserEquipmentPage extends Component<any, State> {

    constructor(props: Readonly<any>) {
        super(props);
        this.state = {
            equipmentList:[],
            isEmpty: false,
            isLoading: false
        }
    }

    componentDidMount(): void {
        userEquipmentService.getUserEquipmentList()

        console.log(window.location.pathname)

        navigationQuery.select()
            .subscribe((state:NavigationState) => {
                this.setState({
                    navigation: state
                })
                if(state.categoryInModal == "" && state.name !== ""){
                    let filterItem = userEquipmentQuery.getAll()
                        .filter(i => i[0] == state.name)
                    this.setState({
                        equipmentList: filterItem
                    })
                }
            })

        userEquipmentQuery.selectAll()
            .subscribe(items => {
                this.setState({
                    ...this.state,
                    equipmentList: items,
                    isEmpty: items.length == 0,
                    isLoading: false
                })
            })
    }

  render(){
        return <div className={"view-container user-equipment--page"}>
            <Breadcrumb className={"bread-crumb--component"}>
                <Breadcrumb.Item href={"/#/dashboard"}>Личный кабинет</Breadcrumb.Item>
                <Breadcrumb.Item active>Мое оборудование</Breadcrumb.Item>
            </Breadcrumb>
            <PageHeading title={"Мое оборудование"} onBack={() => {this.props.history.goBack()}}>
                    <ButtonComponent text={"Добавить"}
                                     onClick={() => {this.handleOnClick()}}
                                     background={"#40D094"}
                                     fontWeight={Weight.BOLD}
                    />
                    <SearchInput onTextInput={(text) => this.handleOnSearch(text) }/>
            </PageHeading>

            <Center><Loader visible={this.state.isLoading}/></Center>
            {this.state.isEmpty ? <Paragraph className={"empty-text"} text={"Список оборудования пуст"} align={Align.CENTER}/>  : this.state.equipmentList.map(i => {
                return <EquipmentsList onClick={(id:ID) => this.handleOnEquipmentItem(id) }
                                       title={i[0]}
                                       equipmentList={i[1]}

                />
            })}
        </div>
    }

    private handleOnClick() {
        modalsStore.update({
            key: ModalsStateType.CATALOG,
        })
    }

    private handleOnEquipmentItem(id:ID) {
        this.props.history.push(`/equipment/${id}`)
    }

    private handleOnSearch(text){
            userEquipmentService.searchGroupItems(text)
    }
}

export default UserEquipmentPage;