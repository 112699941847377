import { Query } from '@datorama/akita';
import { NavigationStore, NavigationState, navigationStore } from './navigation.store';

export class NavigationQuery extends Query<NavigationState> {

  constructor(protected store: NavigationStore) {
    super(store);
  }

}

export const navigationQuery = new NavigationQuery(navigationStore);