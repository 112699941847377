import { Store, StoreConfig } from '@datorama/akita';

export interface ModalsState {
  key: ModalsStateType;
  payload: any
}

export enum ModalsStateType {
  UNKNOWN, CATALOG, SUCCESS, ERROR, SERVICE, EQUIPMENT
}

export function createInitialState(): ModalsState {
  return {
    key: ModalsStateType.UNKNOWN,
    payload: {}
  };
}

@StoreConfig({ name: 'modals', resettable: true })
export class ModalsStore extends Store<ModalsState> {

  constructor() {
    super(createInitialState());
  }

}

export const modalsStore = new ModalsStore();

