import React, {Component} from "react";
import {registrationService} from "./registration.service";
import {H2, Span, SubmitButton, TextInput} from "medissima-ui-kit";
import {registrationQuery} from "./registration.query";

type Data = {
  error?: string,
  requestId?: string,
  onFormSubmit: () => void
}

class RegistrationOrganization extends Component<Data> {

  state = {
    error: undefined,
    value: "",
    isDisabled: false
  };

  componentDidMount(): void {
    registrationQuery.selectError()
      .subscribe(error => {
        this.setState({
          ...this.state,
          error: error
        })
      })
  }

  render() {
    return <div className="auth-form--container">
      <H2 className={"auth-form--title"} text={"Регистрация"} color={"#FFFFFF"}/>
      <form onSubmit={event => this.handleOnFormSubmit(event)}>
        <TextInput
            onInputChange={(value: string) => {
              this.onChangeInput(value)
            }}
            id={'name'}
            placeholder={'Название организации'}
            error={this.state.error}/>
        <SubmitButton
            className={"registration--button"}
            text={"Готово"}
            isDisabled={this.state.isDisabled}/>
      </form>
      <Span text={'4/4'} color={"#FFFFFF"}/>
    </div>

  }

  private handleOnFormSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    this.formValidation();
  }

  private formValidation() {
    registrationService.finishRegistration(
        {
          organization: this.state.value,
          request_id: this.props.requestId
        },
        () => {
          this.props.onFormSubmit();
        });
  }

  private onChangeInput(value: string) {
    this.setState({value: value, isDisabled: false, error: undefined});
  }

  private errorMethod() {
    let error = 'Поле обязательно для заполнения';
    let isDisabled = true;
    this.setState({error: error, isDisabled: isDisabled})
  }
}

export default RegistrationOrganization;