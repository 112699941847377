import React, {Component} from "react";
import {BaseCard, H2, ListItem} from "medissima-ui-kit";
import './ServiceCard.css'
import {ButtonComponent, DatePickerComponent} from "medissima-ui-kit/";
import {CalendarIcon} from "medissima-ui-kit";
import Calendar from 'react-calendar'
import {Paragraph, TextSize, Weight} from "medissima-ui-kit/lib";
import {convertDateToFormat} from "../../../date";
import {ModalsStateType, modalsStore} from "../../modals/state/modals.store";
import * as dateFns from "date-fns";


type Data = {
    className?: string,
    title?: string,
    backgroundList?: string,
    calendar?: boolean,
    list?: Array<any>,
    marker?: ServiceMarker,
    button?: boolean,
    onClick?: () => void
}

export enum ServiceMarker{
    GEAR = "marker-gear",
    CHECK = "marker-check",
    CHEVRON = "marker-chevron"

}

type State = {
    isPickerActive?: boolean,
    date?: Date[],
    list?: Array<any>
}

class ServiceCard extends Component<Data, State> {

    static defaultProps = {
        backgroundList: "#EAF2FC",
        calendar: false,
        button: false
    }

    constructor(props: Readonly<any>) {
        super(props);
        this.state = {
            isPickerActive: false
        }
    }

    addDataText(){
        if(this.state.date !== undefined){

          return <div className={"date-text"}>
              <Paragraph text={`с : ${convertDateToFormat(this.state.date[0])}`} color={"#607088"} size={TextSize.P_18} />
              <Paragraph text={`по : ${convertDateToFormat(this.state.date[1])}`} color={"#607088"} size={TextSize.P_18} />
          </div>
        }
        else {
          return <Paragraph text={"Выбрать период"} color={"#607088"} size={TextSize.P_18}/>
        }
    }

    render() {
        return <BaseCard className={`service-card ${this.props.className}`}>
            <div className={"service-card--title"}>
                <H2 text={this.props.title}/>
                {this.props.button ? <ButtonComponent text={"Добавить"}
                                                      onClick={() => {
                                                          if(this.props.onClick != undefined) {
                                                              this.props.onClick()
                                                          }}}
                                                      background={"#72AAFC"}
                /> : null}
            </div>

            {
                this.props.calendar ? <div className={"date-picker"}>
                    <CalendarIcon
                        onClick={() => this.setState({
                            ...this.state,
                            isPickerActive: !this.state.isPickerActive
                        })}
                    />
                    <Calendar
                        className={this.state.isPickerActive ? "": "hidden"}
                        onChange={(date: Date[]) => this.onDatePicked(date)}
                        value={this.state.date ?? new Date()}
                        selectRange={true}
                    />
                    {this.addDataText()}
                </div> : null
            }
            <ul className={"service-card--list card-list"}>
                {this.state.list !== undefined  ? this.state.list.map(i => {
                    return <ListItem className={`service-list-item ${this.props.marker}`}
                                      text={i.name}
                                      date={i.date}
                                      background={this.props.backgroundList}
                                      color={"#505050"}
                                      onClick={() => this.handleOnClick(i.id)}
                    />
                    }) :  this.props.list?.map(i => {
                    return <ListItem className={`service-list-item ${this.props.marker}`}
                                     text={i.name}
                                     date={i.date}
                                     background={this.props.backgroundList}
                                     color={"#505050"}
                                     onClick={() => this.handleOnClick(i.id)}
                    />
                }) }
            </ul>
        </BaseCard>
    }

    private onDatePicked(date: Date[]) {
        const startDate = date[0];
        const endDate = date[1];
        let day = startDate;
        const days = Array();
        let result = Array()
        while (day <= endDate ){
            days.push(day);
            day = dateFns.addDays(day, 1)
        }
        days.forEach(i => {
            this.props.list?.filter(it => {
                    if (convertDateToFormat(i) == convertDateToFormat(new Date(it.date))) {
                        result?.push(it)
                    }
                }
            )
        });

        this.setState({
            ...this.state,
            date: date,
            isPickerActive: false,
            list: result
        })
    }

    private handleOnClick(id){
        modalsStore.update({
            key: ModalsStateType.SERVICE,
            payload: {
                id: id
            }
        })
    }
}

export default ServiceCard;