import { ID } from '@datorama/akita';
import { ScheduleStore, scheduleStore } from './schedule.store';
import {http} from "../../../index";
import {Schedule} from "./schedule.model";
import {ModalsStateType, modalsStore} from "../../modals/state/modals.store";
import {Nothing} from "../../../models/Nothing";
import {ResponseWrapper} from "medissima-http-client/lib";

export class ScheduleService {

  constructor(private scheduleStore: ScheduleStore) {
  }

  loadServiceSchedule(date){
    http.get<Schedule[]>(`/api/shedule/list/${date}`)
        .then(response => {
          response.data.map(i => {
            scheduleStore.upsert(i.date, i)
          })
        })
        .catch(reason => {
          modalsStore.update({
            key: ModalsStateType.ERROR,
            payload: {
              error: (reason as ResponseWrapper<Nothing>).message
            }
          })
        })
  }

  filterByMonth(date){
      scheduleStore.reset()
      http.get<Schedule[]>(`/api/shedule/list/${date}`)
          .then(response => {
              response.data.map(i => {
                  scheduleStore.upsert(i.date, i)
              })
          })
  }



}

export const scheduleService = new ScheduleService(scheduleStore);