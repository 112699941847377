import { Store, StoreConfig } from '@datorama/akita';

export interface ProfileState {
  address?: string;
  organizationName: string;
  email?: DataHolder,
  name: string,
  phone?: DataHolder
}

interface DataHolder {
  confirmed: boolean
  value: string
}

export function createInitialState(): ProfileState {
  return {
    address: "Не указан",
    organizationName: "",
    email: {
      confirmed: true,
      value: "Не указан"
    },
    name: "",
    phone: {
      confirmed: true,
      value: "Не указан"
    }
  };
}

@StoreConfig({ name: 'profile' })
export class ProfileStore extends Store<ProfileState> {

  constructor() {
    super(createInitialState());
  }

}

export const profileStore = new ProfileStore();

