import { Query } from '@datorama/akita';
import { JournalStore, JournalState, journalStore } from './journal.store';

export class JournalQuery extends Query<JournalState> {

  constructor(protected store: JournalStore) {
    super(store);
  }

}

export const journalQuery = new JournalQuery(journalStore);