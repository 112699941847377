import { Query } from '@datorama/akita';
import { ProfileStore, ProfileState, profileStore } from './profile.store';

export class ProfileQuery extends Query<ProfileState> {

  constructor(protected store: ProfileStore) {
    super(store);
  }

}

export const profileQuery = new ProfileQuery(profileStore);