import React, {Component} from "react";
import {ListItem} from "medissima-ui-kit";

type Data = {
    textDropMenu: string,
    hrefItem?:string,
    onClick?: () => void
}

class DropMenu extends Component<Data> {

    render() {
        return <div className={"drop-menu--container"}>
            <ul>
                <ListItem className={"drop-menu--item"}
                          color={"#FFFFFF"}
                          text={this.props.textDropMenu}
                          href={this.props.hrefItem}
                          onClick={this.props.onClick}
                />
           </ul>
        </div>
    }
}
export default DropMenu;