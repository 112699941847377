import React, {Component} from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {MarkerIcon, Paragraph, PhotoArea, RowComponent} from "medissima-ui-kit/";
import ServiceCard, {ServiceMarker} from "../components/service_card/ServiceCard";
import {ButtonComponent, PageHeading, TextSize, Weight} from "medissima-ui-kit";
import {ModalsStateType, modalsStore} from "../modals/state/modals.store";
import {
    createInitialState,
    EquipmentProfileState,
    equipmentProfileStore
} from "./equipment_profile/equipment-profile.store";
import {equipmentProfileService} from "./equipment_profile/equipment-profile.service";
import {equipmentProfileQuery} from "./equipment_profile/equipment-profile.query";
import {convertDateToFormat} from "../../date";
import InfoFields from "./components/InfoFields";
import {ID} from "@datorama/akita";

class EquipmentProfilePage extends Component<any, EquipmentProfileState> {

    constructor(props: Readonly<any>) {
        super(props);
        this.state = createInitialState()

        equipmentProfileService.getEquipmentItem(props.match.params.id)
    }

    componentDidMount(): void {
        equipmentProfileStore.reset()

        equipmentProfileQuery.select()
            .subscribe(obj => {
                this.setState(obj)
            })
    }

    convertStringToDate(){
        let date = new Date(this.state.details.entry_into_service)
        if(date !== undefined){
            return convertDateToFormat(date)
        } else {
            return ""
        }
    }



    render(){
        return <div className={"view-container equipment-profile"}>
            <Breadcrumb className={"bread-crumb--component"}>
                <Breadcrumb.Item href={"/#/dashboard"}>Личный кабинет</Breadcrumb.Item>
                <Breadcrumb.Item href={"/#/equipment"}>Мое оборудование</Breadcrumb.Item>
                <Breadcrumb.Item active>{this.state.name}</Breadcrumb.Item>
            </Breadcrumb>
            <PageHeading title={this.state.name} onBack={() => {this.props.history.goBack()}}>
                <ButtonComponent text={"Вызвать сервис"}
                                 background={"#F8B058"}
                                 onClick={()=> {}}
                                 fontWeight={Weight.BOLD}
                                 className={"call-service"}
                />
                <ButtonComponent text={"Редактировать"}
                                 background={"#72AAFC"}
                                 onClick={(id:ID)=> this.handleOnClick(this.props.match.params.id)}
                                 fontWeight={Weight.BOLD}
                />
            </PageHeading>

            <div className={"row equipment-info--body"}>
                <div className={"col-md-6"}>
                    <PhotoArea photo={this.state.details.thumb?.url ?? process.env.PUBLIC_URL + "equipment.png"} onError={process.env.PUBLIC_URL + "equipment.png"}/>
                    <InfoFields fields={[
                        {
                            name: "Серийный номер",
                            value: this.state.details.serial
                        },
                        {
                            name: "Выпуск",
                            value: this.state.details.date_manufacture
                        },
                        {
                            name: "Введен в эксплуатацию",
                            value: this.convertStringToDate()
                        }
                    ]} />
                    <div className={"equipment-info--address"}>
                        <MarkerIcon/>
                        <Paragraph text={this.state.address ?? "Неизвестен"} color={"#607088"} />
                    </div>
                    <ServiceCard className={"journal"} list={this.state.reviews.journal} title={"Журнал"} marker={ServiceMarker.CHECK} backgroundList={"#FEF4EA"} button={true} />
                </div>
                <div className={"col-md-6"}>
                    <ServiceCard className={"maintenance"} list={this.state.reviews.upcoming_maintenance} title={"Предстоящие ТО"} calendar={true}  marker={ServiceMarker.GEAR} />
                    <ServiceCard className={"next-check"} list={this.state.reviews.next_verification} title={"Следующая поверка"} marker={ServiceMarker.CHECK}  backgroundList={"#FEF4EA"}/>
                </div>
            </div>
        </div>
    }

    private handleOnClick(id:ID) {
        modalsStore.update({
            key: ModalsStateType.EQUIPMENT,
            payload:{
                id: id
            }
        })
    }
}

export default EquipmentProfilePage;