import React, {Component} from "react";
import {Nothing} from "../../models/Nothing";
import {authService} from "../auth/auth.service";
import {authQuery} from "../auth/auth.query";
import {User} from "../auth/auth.model";
import {Redirect} from "react-router";
import '../../css/Dashboard.css'
import DashboardView from "./DashboardView";

type State = {
  redirect?: {
    should_redirect: boolean,
    url: string
  },
  user?: User
}

class Dashboard extends Component<Nothing, State> {

  componentDidMount(): void {
    authQuery.first()
        .subscribe(user => {
          console.log(user);
          this.setState({
            user: user
          })
        });

    authQuery.listenAuthState(isAuthed => {
      console.log("is authed: " + isAuthed);

      this.setState({
        ...this.state,
        redirect: {
          should_redirect: !isAuthed,
          url: "/"
        }
      });
    });
  }

  render() {
    if(this.state?.redirect?.should_redirect) {
      return <Redirect to={this.state.redirect?.url} />
    }

    return <div className={"view-container"}>
        <DashboardView/>
    </div>;
  }

  private handleOnClick() {
    authService.logout()
  }
}

export default Dashboard