import React, {Component} from "react";
import {Accordion} from "react-bootstrap";
import {Button, Card, CardHeader} from "reactstrap";
import {Link} from "react-router-dom";
import {Paragraph} from "medissima-ui-kit";

type Data = {
    eventKey: number,
    onClick: (key: number) => void,
    className?: string
}

class ServiceSchedule extends Component<Data> {

    render() {
        return <Card eventKey={`${this.props.eventKey}`}>
            <Accordion.Toggle className={"menu-link-button"} as={Button} variant="link" eventKey={`${this.props.eventKey}`}>
                <Link to={"/schedule"}>
            <CardHeader onClick={event => this.handleOnClick(event)} className={`menu-item ${this.props.className}`}>
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M45.1875 27.3984V4.3125C45.1875 3.53577 44.558 2.90625 43.7812 2.90625H36.6562V1.40625C36.6562 0.629517 36.0267 0 35.25 0C34.4733 0 33.8438 0.629517 33.8438 1.40625V2.90625H28.2188V1.40625C28.2188 0.629517 27.5892 0 26.8125 0C26.0358 0 25.4062 0.629517 25.4062 1.40625V2.90625H19.7812V1.40625C19.7812 0.629517 19.1517 0 18.375 0C17.5983 0 16.9688 0.629517 16.9688 1.40625V2.90625H11.3438V1.40625C11.3438 0.629517 10.7142 0 9.9375 0C9.16077 0 8.53125 0.629517 8.53125 1.40625V2.90625H1.40625C0.629517 2.90625 0 3.53577 0 4.3125V40.9688C0 41.7455 0.629517 42.375 1.40625 42.375H24.8254C27.0989 45.7643 30.965 48 35.3438 48C42.3223 48 48 42.3223 48 35.3438C48 32.3372 46.9453 29.5723 45.1875 27.3984ZM8.53125 5.71875V7.125C8.53125 7.90173 9.16077 8.53125 9.9375 8.53125C10.7142 8.53125 11.3438 7.90173 11.3438 7.125V5.71875H16.9688V7.125C16.9688 7.90173 17.5983 8.53125 18.375 8.53125C19.1517 8.53125 19.7812 7.90173 19.7812 7.125V5.71875H25.4062V7.125C25.4062 7.90173 26.0358 8.53125 26.8125 8.53125C27.5892 8.53125 28.2188 7.90173 28.2188 7.125V5.71875H33.8438V7.125C33.8438 7.90173 34.4733 8.53125 35.25 8.53125C36.0267 8.53125 36.6562 7.90173 36.6562 7.125V5.71875H42.375V11.3438H2.8125V5.71875H8.53125ZM2.8125 39.5625V14.1562H42.375V24.8254C40.3627 23.476 37.9435 22.6875 35.3438 22.6875C30.9001 22.6875 26.9846 24.9899 24.7258 28.4637C24.5358 28.3678 24.3215 28.3125 24.0938 28.3125H21.2812C20.5045 28.3125 19.875 28.942 19.875 29.7188C19.875 30.4955 20.5045 31.125 21.2812 31.125H23.4115C23.094 32.0208 22.8743 32.9626 22.7662 33.9375H21.2812C20.5045 33.9375 19.875 34.567 19.875 35.3438C19.875 36.1205 20.5045 36.75 21.2812 36.75H22.7662C22.8743 37.7249 23.0936 38.6667 23.4115 39.5625H2.8125ZM35.3438 45.1875C29.9158 45.1875 25.5 40.7717 25.5 35.3438C25.5 29.9158 29.9158 25.5 35.3438 25.5C40.7717 25.5 45.1875 29.9158 45.1875 35.3438C45.1875 40.7717 40.7717 45.1875 35.3438 45.1875Z" fill="#62E898"/>
                    <path d="M40.9688 33.9375H36.75V29.7188C36.75 28.942 36.1205 28.3125 35.3438 28.3125C34.567 28.3125 33.9375 28.942 33.9375 29.7188V35.3438C33.9375 36.1205 34.567 36.75 35.3438 36.75H40.9688C41.7455 36.75 42.375 36.1205 42.375 35.3438C42.375 34.567 41.7455 33.9375 40.9688 33.9375Z" fill="#62E898"/>
                    <path d="M12.75 17.0625H9.9375C9.16077 17.0625 8.53125 17.692 8.53125 18.4688C8.53125 19.2455 9.16077 19.875 9.9375 19.875H12.75C13.5267 19.875 14.1562 19.2455 14.1562 18.4688C14.1562 17.692 13.5267 17.0625 12.75 17.0625Z" fill="#62E898"/>
                    <path d="M12.75 22.6875H9.9375C9.16077 22.6875 8.53125 23.317 8.53125 24.0938C8.53125 24.8705 9.16077 25.5 9.9375 25.5H12.75C13.5267 25.5 14.1562 24.8705 14.1562 24.0938C14.1562 23.317 13.5267 22.6875 12.75 22.6875Z" fill="#62E898"/>
                    <path d="M12.75 28.3125H9.9375C9.16077 28.3125 8.53125 28.942 8.53125 29.7188C8.53125 30.4955 9.16077 31.125 9.9375 31.125H12.75C13.5267 31.125 14.1562 30.4955 14.1562 29.7188C14.1562 28.942 13.5267 28.3125 12.75 28.3125Z" fill="#62E898"/>
                    <path d="M21.2812 19.875H24.0938C24.8705 19.875 25.5 19.2455 25.5 18.4688C25.5 17.692 24.8705 17.0625 24.0938 17.0625H21.2812C20.5045 17.0625 19.875 17.692 19.875 18.4688C19.875 19.2455 20.5045 19.875 21.2812 19.875Z" fill="#62E898"/>
                    <path d="M21.2812 25.5H24.0938C24.8705 25.5 25.5 24.8705 25.5 24.0938C25.5 23.317 24.8705 22.6875 24.0938 22.6875H21.2812C20.5045 22.6875 19.875 23.317 19.875 24.0938C19.875 24.8705 20.5045 25.5 21.2812 25.5Z" fill="#62E898"/>
                    <path d="M12.75 33.9375H9.9375C9.16077 33.9375 8.53125 34.567 8.53125 35.3438C8.53125 36.1205 9.16077 36.75 9.9375 36.75H12.75C13.5267 36.75 14.1562 36.1205 14.1562 35.3438C14.1562 34.567 13.5267 33.9375 12.75 33.9375Z" fill="#62E898"/>
                    <path d="M32.5312 19.875H35.3438C36.1205 19.875 36.75 19.2455 36.75 18.4688C36.75 17.692 36.1205 17.0625 35.3438 17.0625H32.5312C31.7545 17.0625 31.125 17.692 31.125 18.4688C31.125 19.2455 31.7545 19.875 32.5312 19.875Z" fill="#62E898"/>
                </svg>
                <div  className={"menu-item-title"}>
                    <Paragraph text={"График обслуживания"}/>
                </div>
            </CardHeader>
                </Link>
            </Accordion.Toggle>
        </Card>
    }

    private handleOnClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        this.props.onClick(this.props.eventKey);
    }
}

export default ServiceSchedule;