import {ID, Store, StoreConfig} from '@datorama/akita';
import {Details, Reviews} from "../equipment_state/user-equipment.model";
import {Thumb} from "../../catalog/category_state/category.model";

export interface EquipmentProfileState {
  id: ID;
  address: string;
  category: string;
  details: Details;
  name: string;
  reviews: Reviews
}

export interface UpdateEquipment {
  id: number;
  serial: string;
  instruction: string;
  act: string;
  installedAt: Date;
  address: string
}

export function createInitialState(): EquipmentProfileState {
  return {
    id: 0,
    address: "",
    category: "",
    name: "",
    details: {
      date_manufacture: "",
      entry_into_service: "",
      serial: "",
      thumb: {
        file_name: "",
        url: ""
      }
    },
    reviews : {
      journal: [
        {
          date: "",
          name: "",
          status: ""
        }
      ],
      next_verification: [
        {
          date: "",
          name: "",
          status: ""
        }
      ],
      upcoming_maintenance: [
        {
          date: "",
          name: "",
          status: ""
        }
      ]
    }
  };
}

@StoreConfig({ name: 'equipment-profile', resettable: true })
export class EquipmentProfileStore extends Store<EquipmentProfileState> {

  constructor() {
    super(createInitialState());
  }

}

export const equipmentProfileStore = new EquipmentProfileStore();

