import { EquipmentStore, equipmentStore } from './equipment.store';
import {createEquipment, Equipment} from "./equipment.model";
import {http} from "../../../index";
import {ModalsStateType, modalsStore} from "../../modals/state/modals.store";
import {Nothing} from "../../../models/Nothing";
import {ResponseWrapper} from "medissima-http-client/lib";


export class EquipmentService {

  constructor(private equipmentStore: EquipmentStore) {
  }

  loadEquipmentByCategory(category: number):Promise<void>{
    equipmentStore.setLoading(true)

    return http.get<Equipment[]>(`/api/catalog/category/${category}`)
        .then(response => {
            equipmentStore.reset();
          response.data.map(i =>{
            equipmentStore.upsert(i.id, i)
          })
        })
        .catch(reason =>{
          modalsStore.update({
            key: ModalsStateType.ERROR,
            payload: (reason as ResponseWrapper<Nothing>).message
          })
        })
        .finally(() => {
          equipmentStore.setLoading(false)
        })
  }

  getAllEquipment(){
      http.get<Equipment[]>(`/api/catalog/all`)
          .then(response => {
              response.data.map(i =>{
                  equipmentStore.upsert(i.id, i)
              })
          })
  }

}

export const equipmentService = new EquipmentService(equipmentStore);