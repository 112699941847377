import {http} from "../../index";

export interface UploadData {
  file_name: string,
  file_url: string
}

class FileService {

  uploadFile(file: File): Promise<UploadData> {
    const formData = new FormData();
    formData.append("file", file)

    return http.post<UploadData>("api/upload", formData)
        .then(response => response.data)
  }

}

export const fileService = new FileService();