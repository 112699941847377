import React, {Component} from "react";
import './DashboardMain.css';
import StateCard from "./StateCard";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import ServiceCard, {ServiceMarker} from "../../components/service_card/ServiceCard";
import {dashboardService} from "./state/dashboard.service";
import {createInitialState, DashboardState} from "./state/dashboard.store";
import {dashboardQuery} from "./state/dashboard.query";
import {filterQuery} from "../../filters/akita/filter.query";
import {FilterState} from "../../filters/akita/filter.store";
import {convertDateToFormat} from "../../../date";


class DashboardMain extends Component<any,DashboardState> {
    constructor(props: Readonly<any>) {
        super(props);
        this.state = createInitialState()
    }

    componentDidMount(): void {
        dashboardService.getDashboardState()

        dashboardQuery.select()
            .subscribe(obj => {
                this.setState(obj)
            })
        filterQuery.select()
            .subscribe(filter => {
               let filterItems = this.state.nearest_maintainable
                    .filter(i => convertDateToFormat(new Date(i.date)) == filter.date)
                this.setState({
                    ...this.state,
                    nearest_maintainable: filterItems
                })
            })
    }

    render() {
        return <div className={"dashboard-main"}>
            <Breadcrumb className={"bread-crumb--component"}>
                <Breadcrumb.Item active>Личный кабинет</Breadcrumb.Item>
            </Breadcrumb>
            <div className={"row"}>
                <div className={"col col-sm-6"}>
                    <StateCard expired={this.state.equipment_reviews_state.expired}
                               processed={this.state.equipment_reviews_state.processed}
                               total={this.state.equipment_reviews_state.total}
                               expiredList={this.state.expired}

                    />
                </div>
                <div className={"col col-sm-6"}>
                    <ServiceCard title={"Ближайшие ТО"}
                                 calendar={true}
                                 list={this.state.nearest_maintainable}
                                 marker={ServiceMarker.GEAR}

                    />
                    <ServiceCard title={"Ближайшие поверки"}
                                 list={this.state.nearest_reviews}
                                 backgroundList={"#FEF4EA"}
                                 marker={ServiceMarker.CHECK}
                    />
                </div>
            </div>
            </div>

    }
}

export default DashboardMain;