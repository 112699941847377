import React, {Component} from 'react';
import EmployeesCard from "./EmployeeCard";
import {Employee} from "./employee.model";
import {Nothing} from "../../../models/Nothing";
import {employeesService} from "./employees.service";
import {employeesQuery} from "./employees.query";
import {Permission, Rules} from "../../permissions/permission.model";
import {permissionsQuery} from "../../permissions/permissions.query";
import {permissionsService} from "../../permissions/permissions.service";
import {H2} from "medissima-ui-kit";


type State = {
    listEmployees: Array<Employee>,
    permissions: Array<Permission>
}

class EmployeesList extends Component<Nothing, State> {

    constructor(props: Readonly<Nothing>){
        super(props);

        this.state = {
            listEmployees: [],
            permissions: []
        }
    }

    componentDidMount(): void {
        permissionsService.updatePermissions()
        employeesService.loadingEmployees();

        permissionsQuery
            .selectAll()
            .subscribe(items => {
                this.setState({
                    ...this.state,
                    permissions: items
                })
            });

        employeesQuery
            .selectAll()
            .subscribe(items => {
                this.setState({
                    ...this.state,
                    listEmployees: items
                })
            })
    }

    render(){
        return <div className={"row employees-setting"}>
                <div className={"col col-md-6"}>
                    <H2 className={"title"} color={"#303030"} text={"Пользователи"}/>
                    <ul className={"employees-setting--list"}>
                        {this.state.listEmployees.map(item => {
                            if(!item.is_admin){
                                return <li><EmployeesCard
                                    isOwner={ this.state.permissions.find(i => i.id == Rules.OWNER)?.granted ?? false }
                                    canDelete={ this.state.permissions.find(i => i.id == Rules.DELETE)?.granted ?? false }
                                    canEdit={ this.state.permissions.find(i => i.id == Rules.EDIT)?.granted ?? false }
                                    onDelete={(id) => employeesService.deleteUser(id)}
                                    employee={item}
                                /></li>
                            }
                        })}
                    </ul>
                </div>
                <div className={"col col-md-6"}>
                    <H2 className={"title"} color={"#303030"} text={"Администраторы"}/>
                    <ul className={"employees-setting--list"}>
                        {this.state.listEmployees.map(item => {
                            if(item.is_admin){
                                return <li><EmployeesCard
                                    isOwner={ this.state.permissions.find(i => i.id == Rules.OWNER)?.granted ?? false }
                                    canDelete={ this.state.permissions.find(i => i.id == Rules.DELETE)?.granted ?? false }
                                    canEdit={ this.state.permissions.find(i => i.id == Rules.EDIT)?.granted ?? false }
                                    onDelete={(id) => employeesService.deleteUser(id)}
                                    employee={item} admin
                                /></li>
                            }
                        })}
                    </ul>
                </div>
        </div>

    }
}

export default EmployeesList;