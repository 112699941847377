import React, {Component} from 'react';
import "../../css/Equipment.css"
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {Align, CategoryCard, Center, PageHeading, Paragraph} from "medissima-ui-kit/";
import {ID} from "@datorama/akita";
import {Loader} from "medissima-ui-kit/lib";
import {Nothing} from "../../models/Nothing";
import {Category} from "./category_state/category.model";
import {categoryService} from "./category_state/category.service";
import {categoryQuery} from "./category_state/category.query";


type State = {
    categoryList: Category[],
    isEmpty: boolean,
    isLoading: boolean
}

class EquipmentCatalogPage extends Component<any, State> {

    constructor(props: Readonly<Nothing>) {
        super(props);
        this.state = {
            categoryList: [],
            isEmpty: true,
            isLoading: true
        }
    }

    componentDidMount(): void {
        categoryService.getCategoryList()

        categoryQuery.selectAll()
            .subscribe(items => {
                this.setState({
                    ...this.state,
                    categoryList: items,
                    isEmpty: items.length == 0,
                    isLoading: false
                })
            })
    }

    render(){
        return <div className={"view-container equipment-catalog--page"}>
            <Breadcrumb className={"bread-crumb--component"}>
                <Breadcrumb.Item href={"/#/dashboard"}>Личный кабинет</Breadcrumb.Item>
                <Breadcrumb.Item active aria-current="page">Каталог оборудования</Breadcrumb.Item>
            </Breadcrumb>
            <PageHeading title={"Каталог оборудования"} onBack={() => this.props.history.goBack()}/>
            <Center>
                {
                    this.state.isEmpty ? <Paragraph className={"empty-text"} text={"Ваш каталог пуст"} align={Align.CENTER}/> : null
                }
            </Center>
            <Center><Loader visible={this.state.isLoading}/></Center>
            <div className={"equipment-catalog--cards"}>
                {this.state.categoryList.map(i => {
                    return <CategoryCard id={i.id}
                                         category={i.name}
                                         btnText={"Ознакомиться"}
                                         onClick={(id:ID) => this.handleOnEquipmentItem(id)}
                                         photo={i.thumb.url}
                    />
                })}
            </div>
        </div>
    }
    private handleOnEquipmentItem(id: ID) {
        this.props.history.push(`/catalog/category/${id}`)
    }
}

export default EquipmentCatalogPage;