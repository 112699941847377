import React, {Component} from "react";
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import {Nothing} from "../models/Nothing";
import Dashboard from "./dashboard/Dashboard";
import SignUp from "./registration/SignUp";
import SignIn from "./auth/SignIn";
import PasswordRestore from "./password_restore/PasswordRestore";
import {authQuery} from "./auth/auth.query";
import ProfilePage from "./user_settings/profile/ProfilePage";
import Sidebar from "./dashboard/Sidebar";
import NotificationSettingsPage from "./user_settings/NotificationSettingsPage";
import EmployeesPage from "./user_settings/EmployeesPage";
import UserEquipmentPage from "./user_equipment/UserEquipmentPage";
import EquipmentProfilePage from "./user_equipment/EquipmentProfilePage";
import ServiceSchedulePage from "./service_shedule/ServiceSchedulePage";
import ServiceCalendar from "./service_shedule/ServiceCalendar";
import EquipmentCatalogPage from "./catalog/EquipmentCatalogPage";
import EquipmentsSection from "./catalog/EquipmentsSection";
import EquipmentInfoPage from "./catalog/EquipmentInfoPage";


type State = {
    isAuthenticated: boolean
}

class Body extends Component<Nothing, State> {

    constructor(readonly props: Nothing) {
        super(props);

        const authSession = authQuery.findAuthSession();

        this.state = {
            isAuthenticated: authSession !== undefined
        }
    }

    componentDidMount(): void {
        authQuery.listenAuthState(isAuthed => {
            this.setState({
                isAuthenticated: isAuthed
            })
        })
    }

    render() {

        return <div className={"row body-row"}>
            <Router>
                { this.state.isAuthenticated ? <Sidebar/> : null }
                <div className={`body-col ${this.state.isAuthenticated ? "col" : "col-12"}`}>
                    <Switch>
                        <Route exact path="/" component={this.state.isAuthenticated ? Dashboard : SignIn} />
                        <Route path="/sign-up" component={this.state.isAuthenticated ? Dashboard : SignUp} />
                        <Route path="/sign-in" component={this.state.isAuthenticated ? Dashboard : SignIn} />
                        <Route path="/password-restore" component={PasswordRestore} />
                        <Route path="/dashboard" component={this.state.isAuthenticated ? Dashboard : SignIn} />
                        <Route path="/settings" component={this.state.isAuthenticated ? ProfilePage : SignIn} />
                        <Route path="/notification" component={this.state.isAuthenticated ? NotificationSettingsPage : SignIn}/>
                        <Route path="/employees" component={this.state.isAuthenticated ? EmployeesPage : SignIn}/>
                        <Route exact path="/equipment" component={this.state.isAuthenticated ? UserEquipmentPage : SignIn}/>
                        <Route exact path="/equipment/:id" component={this.state.isAuthenticated ? EquipmentProfilePage : SignIn}/>
                        <Route exact path="/catalog" component={this.state.isAuthenticated ? EquipmentCatalogPage : SignIn}/>
                        <Route path="/catalog/category/:id" component={this.state.isAuthenticated ? EquipmentsSection : SignIn}/>
                        <Route path="/catalog/:id" component={this.state.isAuthenticated ? EquipmentInfoPage : SignIn}/>
                        <Route path="/schedule" component={this.state.isAuthenticated ? ServiceSchedulePage : SignIn}/>
                    </Switch>
                </div>
                <div className={"body-col col"} style={{position:"absolute"}}>
                    <Switch>
                        <Route path="/calendar" component={this.state.isAuthenticated ? ServiceCalendar : SignIn}/>
                    </Switch>
                </div>
            </Router>
            </div>

    }

}

export default Body