import { AuthStore, authStore } from './auth.store';
import {User} from "./auth.model";
import {Nothing} from "../../models/Nothing";
import {permissionsStore} from "../permissions/permissions.store";
import {ResponseWrapper} from "medissima-http-client/lib";
import {http} from "../../index";
import {resetStores} from "@datorama/akita";

export class AuthService {

  constructor(private authStore: AuthStore) {}

  login(
    data: {email: string, password: string},
    onSuccess: (b: boolean) => void,
    onError: (message: string) => void
  ) {

    http.post<User>("api/sign-in", data)
        .then(result => {
          const response = result.data;

          console.log("User: -> ");
          console.log(response);
          this.addAuthData(response);

          onSuccess(true)
        })
        .catch(reason => {
          const result = reason.response.data as any as ResponseWrapper<Nothing>;
          console.log(result);

          onError(result.message)
        });
  }

  addAuthData(user: User) {
    authStore.upsert(user.id, user);
  }

  logout() {
    resetStores()
  }

}

export const authService = new AuthService(authStore);