import React, {Component} from "react";
import UserEquipment from "./UserEquipment";
import '../../../css/Menu.css'
import ServiceSchedule from "./ServiceSchedule";
import UserSettings from "./UserSettings";
import CatalogEquipment from "./CatalogEquipment";
import {Accordion} from "react-bootstrap";


type State = {
    selected?: number
}

class SidebarMenu extends Component<any, State> {

    constructor(props: Readonly<any>){
        super(props);
        this.selectedMethod = this.selectedMethod.bind(this);
        this.state = {
            selected: undefined
        }
    }

    selectedMethod(key: number) {
        this.setState({
            selected: key
        })
    }

    render() {
        return <Accordion >
            <UserEquipment onClick={this.selectedMethod} className={ window.location.hash.includes('equipment') ? "active" : ""} eventKey={0} />
            <ServiceSchedule onClick={this.selectedMethod} className={ window.location.hash.includes('schedule') ? "active" : ""} eventKey={1} />
            <UserSettings onClick={this.selectedMethod} className={ ['settings', 'notification', 'employees'].some(i => window.location.hash.includes(i))  ? "active" : ""} eventKey={2} />
            <CatalogEquipment onClick={this.selectedMethod} className={ window.location.hash.includes('catalog') ? "active" : ""} eventKey={3} />
        </Accordion>
    }
}

export default SidebarMenu;