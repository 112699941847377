import React, {Component} from "react";
import {registrationService} from "./registration.service";
import {H2, Span, SubmitButton, TextInput} from "medissima-ui-kit";
import {registrationQuery} from "./registration.query";

type Data = {
    error?: string,
    onFormSubmit: (requestId?: string) => void
}

class RegistrationName extends Component<Data> {

    state = {
        error: undefined,
        value: "",
        isDisabled: false
    };

    componentDidMount(): void {
        registrationQuery.selectError()
          .subscribe(error => {
              this.setState({
                  ...this.state,
                  error: error
              })
          })
    }

    render() {
        return <div className="auth-form--container">
            <H2 className={"auth-form--title"} text={"Регистрация"} color={"#FFFFFF"}/>
            <form onSubmit={event => this.handleOnFormSubmit(event)}>
                <TextInput
                    onInputChange={(value:string) => {this.onChangeInput(value)}}
                    id={'name'}
                    placeholder={'Как Вас зовут?'}
                    error={this.state.error} />
                <SubmitButton
                    className={"registration--button"}
                    text={"Далее"}
                    isDisabled={this.state.isDisabled}/>
            </form>
            <Span text={'1/4'} color={"#FFFFFF"}/>
        </div>
    }

    private handleOnFormSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        this.formValidation();
    }

    private formValidation(){
        registrationService.initRegistration(this.state.value, requestId => {
            this.props.onFormSubmit(requestId);
        })
    }

    private onChangeInput(value: string) {
        this.setState({value: value, isDisabled: false, error: undefined});
    }

    private errorMethod() {
        let error = 'Поле обязательно для заполнения';
        let isDisabled = true;
        this.setState({error: error, isDisabled: isDisabled })
    }

}

export default RegistrationName;