import React, {Component} from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {ButtonComponent, PageHeading, TableHead, Weight} from "medissima-ui-kit";
import {Paragraph, TableBody, TextSize} from "medissima-ui-kit/lib";
import ServiceFilter from "../filters/ServiceFilter";
import {Schedule} from "./akita/schedule.model";
import {scheduleService} from "./akita/schedule.service";
import {scheduleQuery} from "./akita/schedule.query";
import {convertDateToFormat} from "../../date";
import {FilterState} from "../filters/akita/filter.store";
import {filterQuery} from "../filters/akita/filter.query";

type State = {
    serviceList: Schedule[],
    filter?: FilterState,
}

class ServiceSchedulePage extends Component<any, State> {

    constructor(props: Readonly<any>) {
        super(props);
        this.state = {
            serviceList: []
        }
    }

    componentDidMount(): void {
        let date = new Date()
        scheduleService.loadServiceSchedule(convertDateToFormat(date, "yyyy-MM-dd"))

        scheduleQuery.selectAll()
            .subscribe(items =>{
                this.setState({
                    ...this.state,
                    serviceList: items
                })
            })
    }

    render(){
        return <div className={"view-container service-schedule--page"}>
            <Breadcrumb className={"bread-crumb--component"}>
                <Breadcrumb.Item href={"/#/dashboard"}>Личный кабинет</Breadcrumb.Item>
                <Breadcrumb.Item active>График обслуживания</Breadcrumb.Item>
            </Breadcrumb>
            <PageHeading title={"График обслуживания"} onBack={() => {this.props.history.goBack()}}>
                <ButtonComponent
                    text={"Сформировать график обслуживания"}
                    onClick={() => this.handleOnCalendarView()}
                    background={"#40D094"}
                    fontWeight={Weight.BOLD}
                />
            </PageHeading>
            <ServiceFilter list={this.state.serviceList.map(i => i)}
                           onFilter={(filterData: [string, string?][]) => this.handleOnFilter(filterData)}
            />
            <table style={{border: "none"}} className={"table table-bordered table-container service-schedule-table"}>
                <TableHead filterArrow={true} titles={[
                    "Оборудование",
                    "Дата",
                    "Виды работ"
                ]}/>
                <tbody className={"table-body"}>
                {this.state.serviceList.map(i => {
                    return <tr>
                        <TableBody id={i.name}>
                            <Paragraph color={"#505050"} size={TextSize.P_16} text={i.name}/>
                            <Paragraph color={"#607088"} size={TextSize.P_14} text={i.category_name}/>
                        </TableBody>
                        <TableBody id={i.name} text={convertDateToFormat(new Date(i.date))}/>
                        <TableBody id={i.name} text={i.job_type}/>
                        </tr>
                })}
                </tbody>
            </table>
        </div>
    }

    private handleOnCalendarView() {
        this.props.history.push(`/calendar`);
    }

    private handleOnFilter(filterData: [string, string?][]) {
        let schedule = scheduleQuery.getAll();

        if(filterData.length === 0) {
            this.setState({
              ...this.state,
              serviceList: schedule
            })

            return;
        }

        filterData.forEach(filterItem => {
            schedule = scheduleQuery.getAll().filter(i => {
                 console.log(filterItem[1], i.name)
                switch (filterItem[0]) {
                    case "Дата": return i.date === filterItem[1];
                    case "Оборудование": return i.name === filterItem[1];
                    case "Вид работ": return i.job_type === filterItem[1];
                }
            })
        })

        this.setState({
          ...this.state,
          serviceList: schedule
        })
    }
}

export default ServiceSchedulePage;