import { QueryEntity } from '@datorama/akita';
import { EmployeesStore, EmployeesState, employeesStore } from './employees.store';

export class EmployeesQuery extends QueryEntity<EmployeesState> {

  constructor(protected store: EmployeesStore) {
    super(store);
  }

}

export const employeesQuery = new EmployeesQuery(employeesStore);
