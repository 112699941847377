import {RegistrationStore, registrationStore} from './registration.store';
import {EmailData, OrganizationData, PasswordData, Registration} from "./registration.model";
import {Nothing} from "../../models/Nothing";
import {authService} from "../auth/auth.service";
import {User} from "../auth/auth.model";
import {http} from "../../index";
import {ResponseWrapper} from "medissima-http-client/lib";

export class RegistrationService {

  constructor(private registrationStore: RegistrationStore) {
  }

  private sendRequest(url: string, data: any, callback: (requestId: string) => void) {
    registrationStore.setError(null);

    http.post<Registration>(url, data)
      .then(response => {
        callback(response.data.request_id as string);
      })
      .catch(reason => {
        const result = reason.response.data as any as ResponseWrapper<Nothing>;
        console.log(result);

        registrationStore.setError(result.message)
      })
  }

  initRegistration(name: string, callback: (requestId: string) => void) {
    this.sendRequest("api/sign-up/name", {name: name}, callback);
  }

  sendEmail(data: EmailData, callback: (requestId: string) => void) {
    this.sendRequest("api/sign-up/email", data, callback);
  }

  sendPassword(data: PasswordData, callback: (requestId: string) => void) {
    this.sendRequest("api/sign-up/password", data, callback);
  }

  finishRegistration(data: OrganizationData, callback: () => void) {
    registrationStore.setError(null);

    http.rawPost<User>("api/sign-up/organization", data)
      .then(response => {
        console.log(response);
        authService.addAuthData(response);
        callback()
      })
      .catch(reason => {
        console.log(reason);

        registrationStore.setError("Ошибка во время регистрации.")
      })
  }

}

export const registrationService = new RegistrationService(registrationStore);