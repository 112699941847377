import { Query } from '@datorama/akita';
import { FilterStore, FilterState, filterStore } from './filter.store';

export class FilterQuery extends Query<FilterState> {

  constructor(protected store: FilterStore) {
    super(store);
  }

}

export const filterQuery = new FilterQuery(filterStore);