import { ID } from '@datorama/akita';
import { UserEquipmentStore, userEquipmentStore } from './user-equipment.store';
import {http} from "../../../index";
import {UpdateEquipment, UserEquipment} from "./user-equipment.model";
import {ModalsStateType, modalsStore} from "../../modals/state/modals.store";
import {Nothing} from "../../../models/Nothing";
import {ResponseWrapper} from "medissima-http-client/lib";
import {equipmentProfileStore} from "../equipment_profile/equipment-profile.store";

export class UserEquipmentService {

  constructor(private userEquipmentStore: UserEquipmentStore) {
  }
  getUserEquipmentList():Promise<void>{
      userEquipmentStore.setLoading(true);

      return http.get<UserEquipment[]>("/api/registry")
          .then(response => {
              userEquipmentStore.reset()
              let dataArray = Object.keys(response.data).map(function (key) {
                  return [String(key), response.data[key]];
              });
              dataArray.map(i => {
                  userEquipmentStore.upsert(i[0], i)
              })
          })
          .catch(reason => {
              modalsStore.update({
                  key: ModalsStateType.ERROR,
                  payload: (reason as ResponseWrapper<Nothing>).message
              })
          })
          .finally(() => {
              userEquipmentStore.setLoading(false)
          })
  }

    addEquipmentToCompany(id: ID){
     http.post(`/api/registry/add/${id}`)
          .then(response => {
              equipmentProfileStore.update(response.data.id)
              window.location.href = `/#/equipment/${response.data.id}`
          })
         .then(response => {
             modalsStore.update({
                 key: ModalsStateType.SUCCESS,
                 payload: {
                     text: "Оборудование успешно добавлено"
                 }
             })
         })
          .catch(reason => {
              modalsStore.update({
                  key: ModalsStateType.ERROR,
                  payload: {
                      error: (reason as ResponseWrapper<Nothing>).message
                  }
              })
          })

    }

    searchGroupItems(text:string){
     http.get(`/api/registry/search?text=${text}`)
          .then(response => {
              userEquipmentStore.reset()
              let dataArray = Object.keys(response.data).map(function (key) {
                  return [String(key), response.data[key]];
              });
              dataArray.map(i => {
                  userEquipmentStore.upsert(i[0], i)
              })
          })
    }
}

export const userEquipmentService = new UserEquipmentService(userEquipmentStore);