import React, {Component} from 'react';
import Body from "./Body";
import '../css/App.css'
import {ToastContainer} from "react-toastify";
import {Helmet} from "react-helmet";
import ModalWindow from "./modals/ModalWindow";

class App extends Component {

  render() {
    return (
        <div className={'App'}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Medissima</title>
            <link rel="canonical" href="http://medissima.ru" />
          </Helmet>
          <Body />
          <ToastContainer/>
          <ModalWindow/>
        </div>
    );
  }
};

export default App;