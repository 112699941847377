import {ID} from '@datorama/akita';
import {ProfileState, ProfileStore, profileStore} from './profile.store';
import {Nothing} from "../../../models/Nothing";
import {http} from "../../../index";

export class ProfileService {

    constructor(private profileStore: ProfileStore) {
    }

    loadProfile() {
        http.get<ProfileState>("/api/settings/profile")
            .then(response => {
                const data = response.data;
                console.log(data);
                profileStore.update(data)
            })
    }

    updateAddress(value?: string) {
        this.updateProfile({
            address: value
        });
    }

    updateCompanyName(value: string | undefined) {
        this.updateProfile({
            organization_name: value
        });
    }

    updateUserName(value: string | undefined) {
        this.updateProfile({
            name: value
        });
    }

    updateEmail(value: string | undefined) {
        this.updateProfile({
            email: value
        });
    }

    updatePhone(value: string | undefined) {
        this.updateProfile({
            phone: value
        });
    }

    private updateProfile(value?: any) {
        http.patch<ProfileState>("/api/settings/profile", value)
            .then(response => {
            const data = response.data;
            console.log(data);
            profileStore.update(data)
        })
    }

    verifyEmail() {
        http.post<ProfileState>("/api/settings/verify-email")
            .then(response => {
                profileStore.update(response.data)
            })
    }

    verifyPhone() {
        http.post<ProfileState>("/api/settings/verify-phone")
            .then(response => {
                profileStore.update(response.data)
            })
    }

    changePassword(oldPass: string, newPass: string) {
        http.post<Nothing>("/api/settings/change-password", {
            old: oldPass,
            new: newPass
        }).then(response => {
            console.log(response.status)
        })

    }
}

export const profileService = new ProfileService(profileStore);