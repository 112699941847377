import React, {Component} from 'react';
import './Settings.css';
import NotificationSettings from "./notification_settings/NotificationSettings";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {PageHeading} from "medissima-ui-kit";

class NotificationSettingsPage extends Component<any> {

    render(){
        return <div className={"user-setting--page"}>
            <Breadcrumb className={"bread-crumb--component"}>
                <Breadcrumb.Item href={"/#/dashboard"}>Личный кабинет</Breadcrumb.Item>
                <Breadcrumb.Item href={"/#/settings"}>Мои настройки</Breadcrumb.Item>
                <Breadcrumb.Item active>Оповещения</Breadcrumb.Item>
            </Breadcrumb>
            <PageHeading title={"Мои настройки"} onBack={() => {this.props.history.goBack()}}/>
            <NotificationSettings/>
        </div>
    }
}

export default NotificationSettingsPage;