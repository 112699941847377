import { Store, StoreConfig } from '@datorama/akita';

export interface NavigationState {
 name?: string,
  categoryInModal?: string
}

export function createInitialState(): NavigationState {
  return {
    name: '',
    categoryInModal: ''
  };
}

@StoreConfig({ name: 'navigation', resettable: true })
export class NavigationStore extends Store<NavigationState> {

  constructor() {
    super(createInitialState());
  }

}

export const navigationStore = new NavigationStore();

