import React, {Component} from "react";
import {Button, Card, CardHeader} from "reactstrap";
import {Link} from "react-router-dom";
import {Accordion} from "react-bootstrap";
import {Paragraph} from "medissima-ui-kit";

type Data = {
    eventKey: number,
    onClick: (key: number) => void,
    className?: string
}

class CatalogEquipment extends Component<Data> {

    render() {
        return <Card eventKey={`${this.props.eventKey}`}>
            <Accordion.Toggle className={"menu-link-button"} as={Button} variant="link" eventKey={`${this.props.eventKey}`}>
                <Link to={"/catalog"}>
                <CardHeader onClick={event => this.handleOnClick(event)} className={`menu-item ${this.props.className}`}>
                    <svg width="46" height="48" viewBox="0 0 46 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.48276 35.5862C1.93959 35.5862 2.31035 35.2163 2.31035 34.7586C2.31035 33.3898 3.42428 32.2758 4.79311 32.2758C6.16193 32.2758 7.27586 33.3898 7.27586 34.7586C7.27586 35.2163 7.64662 35.5862 8.10345 35.5862C8.56028 35.5862 8.93104 35.2163 8.93104 34.7586C8.93104 32.4769 7.07476 30.6207 4.79311 30.6207C2.51145 30.6207 0.655174 32.4769 0.655174 34.7586C0.655174 35.2163 1.02593 35.5862 1.48276 35.5862Z" fill="#FFB800"/>
                        <path d="M1.48276 30.6207C1.93959 30.6207 2.31035 30.2507 2.31035 29.7931C2.31035 28.4243 3.42428 27.3103 4.79311 27.3103C6.16193 27.3103 7.27586 28.4243 7.27586 29.7931C7.27586 30.2507 7.64662 30.6207 8.10345 30.6207C8.56028 30.6207 8.93104 30.2507 8.93104 29.7931C8.93104 27.5114 7.07476 25.6552 4.79311 25.6552C2.51145 25.6552 0.655174 27.5114 0.655174 29.7931C0.655174 30.2507 1.02593 30.6207 1.48276 30.6207Z" fill="#FFB800"/>
                        <path d="M1.48276 25.6552C1.93959 25.6552 2.31035 25.2852 2.31035 24.8276C2.31035 23.4587 3.42428 22.3448 4.79311 22.3448C6.16193 22.3448 7.27586 23.4587 7.27586 24.8276C7.27586 25.2852 7.64662 25.6552 8.10345 25.6552C8.56028 25.6552 8.93104 25.2852 8.93104 24.8276C8.93104 22.5459 7.07476 20.6896 4.79311 20.6896C2.51145 20.6896 0.655174 22.5459 0.655174 24.8276C0.655174 25.2852 1.02593 25.6552 1.48276 25.6552Z" fill="#FFB800"/>
                        <path d="M1.48276 20.6896C1.93959 20.6896 2.31035 20.3197 2.31035 19.8621C2.31035 18.4932 3.42428 17.3793 4.79311 17.3793C6.16193 17.3793 7.27586 18.4932 7.27586 19.8621C7.27586 20.3197 7.64662 20.6896 8.10345 20.6896C8.56028 20.6896 8.93104 20.3197 8.93104 19.8621C8.93104 17.5804 7.07476 15.7241 4.79311 15.7241C2.51145 15.7241 0.655174 17.5804 0.655174 19.8621C0.655174 20.3197 1.02593 20.6896 1.48276 20.6896Z" fill="#FFB800"/>
                        <path d="M2.31035 14.8965C2.31035 13.5277 3.42428 12.4138 4.79311 12.4138C6.16193 12.4138 7.27586 13.5277 7.27586 14.8965C7.27586 15.3542 7.64662 15.7241 8.10345 15.7241C8.56028 15.7241 8.93104 15.3542 8.93104 14.8965C8.93104 12.6149 7.07476 10.7586 4.79311 10.7586C2.51145 10.7586 0.655174 12.6149 0.655174 14.8965C0.655174 15.3542 1.02593 15.7241 1.48276 15.7241C1.93959 15.7241 2.31035 15.3542 2.31035 14.8965Z" fill="#FFB800"/>
                        <path d="M42.037 0.00331035H40.3793H34.5862H14.7241V0H3.96552V5.87669C2.07945 6.26152 0.655174 7.93241 0.655174 9.93104C0.655174 10.3887 1.02593 10.7586 1.48276 10.7586C1.93959 10.7586 2.31035 10.3887 2.31035 9.93104C2.31035 8.56221 3.42428 7.44828 4.7931 7.44828C6.16193 7.44828 7.27586 8.56221 7.27586 9.93104C7.27586 10.3887 7.64662 10.7586 8.10345 10.7586C8.56028 10.7586 8.93104 10.3887 8.93104 9.93104C8.93104 7.93241 7.50676 6.26152 5.62069 5.87669V1.65517H13.069V46.3448H5.62069V43.0345V40.5517C5.62069 40.0941 5.24993 39.7241 4.7931 39.7241C4.33628 39.7241 3.96552 40.0941 3.96552 40.5517V42.0538C3.00469 41.7112 2.31035 40.8017 2.31035 39.7241C2.31035 38.3553 3.42428 37.2414 4.7931 37.2414C6.16193 37.2414 7.27586 38.3553 7.27586 39.7241C7.27586 40.1818 7.64662 40.5517 8.10345 40.5517C8.56028 40.5517 8.93104 40.1818 8.93104 39.7241C8.93104 37.4425 7.07476 35.5862 4.7931 35.5862C2.51145 35.5862 0.655174 37.4425 0.655174 39.7241C0.655174 41.7228 2.07945 43.3937 3.96552 43.7785V48H13.069H14.7241H34.5862H40.3793H42.037C43.861 48 45.3448 46.5161 45.3448 44.693V3.31034C45.3448 1.48717 43.861 0.00331035 42.037 0.00331035ZM14.7241 46.3448V1.65848H34.5862V46.3448H14.7241ZM36.2414 46.3448V1.65848H38.7241V46.3448H36.2414ZM43.6897 44.693C43.6897 45.6033 42.9481 46.3448 42.037 46.3448H40.3793V1.65848H42.037C42.9481 1.65848 43.6897 2.39917 43.6897 3.31034V44.693Z" fill="#FFB800"/>
                    </svg>
                    <div className={"menu-item-title"}>
                        <Paragraph text={"Каталог оборудования"}/>
                    </div>
                </CardHeader>
            </Link>
            </Accordion.Toggle>
            </Card>
    }

    private handleOnClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        this.props.onClick(this.props.eventKey);
    }
}

export default CatalogEquipment;