import React, {Component} from 'react';
import '../Settings.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import BasicSettings from "./BasicSettings";
import {profileService} from "./profile.service";
import {PageHeading} from "medissima-ui-kit";

class ProfilePage extends Component<any> {

    componentDidMount(): void {
        profileService.loadProfile();
    }

    render() {
        return <div className={"user-setting--page"}>
            <Breadcrumb className={"bread-crumb--component"}>
                <Breadcrumb.Item href={"/#/dashboard"}>Личный кабинет</Breadcrumb.Item>
                <Breadcrumb.Item active>Мои настройки</Breadcrumb.Item>
            </Breadcrumb>
            <PageHeading title={"Мои настройки"} onBack={() => {this.props.history.goBack()}}/>
            <BasicSettings/>
        </div>
    }
}

export default ProfilePage;