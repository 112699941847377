import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Category } from './category.model';

export interface CategoryState extends EntityState<Category> {}

@StoreConfig({
  name: 'category',
  idKey: '_id',
  resettable: true
})
export class CategoryStore extends EntityStore<CategoryState> {

  constructor() {
    super();
  }

}

export const categoryStore = new CategoryStore();

