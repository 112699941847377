import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Employee } from './employee.model';

export interface EmployeesState extends EntityState<Employee> {}

@StoreConfig({
  name: 'employees',
  idKey: '_id',
  resettable: true
})
export class EmployeesStore extends EntityStore<EmployeesState> {

  constructor() {
    super();
  }

}

export const employeesStore = new EmployeesStore();

