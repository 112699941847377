import { Query } from '@datorama/akita';
import { EquipmentProfileStore, EquipmentProfileState, equipmentProfileStore } from './equipment-profile.store';

export class EquipmentProfileQuery extends Query<EquipmentProfileState> {

  constructor(protected store: EquipmentProfileStore) {
    super(store);
  }

}

export const equipmentProfileQuery = new EquipmentProfileQuery(equipmentProfileStore);