import {QueryEntity} from '@datorama/akita';
import {AuthStore, AuthState, authStore} from './auth.store';
import {User} from "./auth.model";

export class AuthQuery extends QueryEntity<AuthState> {

    constructor(protected store: AuthStore) {
        super(store);
    }

    findAuthSession(): User | undefined {
        return this.getAll().values().next().value
    }

    first() {
        return this.selectLast()
    }

    listenAuthState(callback: (isAuthed: boolean) => void) {
        console.log("///--------listenAuthState----------///");

        this.first()
            .subscribe(authState => {
                console.log(authState);
                callback(authState !== undefined)
            })
    }

}

export const authQuery = new AuthQuery(authStore);
